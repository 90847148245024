import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/salesHeader";
import BottomNav from "../../components/NavBar/navBottom";
import "./index.sass";
import SalesForm from "../../Pages/salesForm";
import PartnerForm from "../../Pages/partnerForm";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box, Card, Grid } from "@mui/material";
import { AppDispatch } from "../../storage/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loader from "../../components/Loader";
import SnackBar from "../../components/SnackBar/snackBarPopPup";
import SalesLogin from "../../Pages/salesLogin";
import SalesViewCamp from "../../Pages/salesViewCamp";
import PartnerView from "../../Pages/viewPartner";
import Confirmation from "../../Pages/partnerCreated";
import { getUserData } from "../../services/actionSlice";
import SecureStorage from "../../config/SecureStorage";
const theme = createTheme({
  palette: {
    primary: {
      main: "#E00646",
    },
    secondary: {
      main: "#2677F0",
    },
  },
});

export default function Main() {
  // const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(0);

  const [screen, setScreen] = useState<string>("otp");

  //link queries

  const [campData, setCampData] = useState<any>({});
  const [partnerData, setPartnerData] = useState<any>({});
  //data

  const loading = useSelector((state: any) => state.webAppSlice.loading);
  const campList = useSelector((state: any) => state.webAppSlice.campList);
  const userData = useSelector((state: any) => state.webAppSlice.userData);

  const snackData = useSelector((state: any) => state.webAppSlice.snackbarData);
useEffect(() => {
  if(!userData?.profile&&SecureStorage.getItem("token")){
    dispatch(getUserData({}))
  }

}, [userData,SecureStorage.getItem("token")])

  return (
    <ThemeProvider theme={theme}>
      <div className="containerMain">
        <>
          <Header screen={screen} />
          {screen === "otp" ? (
            <div className="rectPink">
              <SalesLogin setScreen={setScreen} />
            </div>
          ) : screen === "menu" ? (
            <Box sx={{ flexGrow: 1, padding: "33% 0px" }}>
              <Grid container className={"dialogContent"}>
                <Grid item xs={6} textAlign="center">
                  <Card
                    sx={{
                      borderRadius: "16px",
                      p: 1,
                      cursor: "pointer",
                      height: "100px",
                    }}
                    onClick={() => {
                      setScreen("form");
                    }}
                  >
                    <div style={{ color: "#114A76", padding: "20% 0px" }}>
                      New Request
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={6} textAlign="center">
                  <Card
                    sx={{
                      borderRadius: "16px",
                      p: 1,
                      cursor: "pointer",
                      height: "100px",
                    }}
                    onClick={() => setScreen("view")}
                  >
                    <div style={{ color: "#114A76", padding: "15% 0px" }}>
                      Approval Pending <br />
                      <sub style={{ color: "#E00646" }}>{campList?.count}</sub>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          ) : screen === "form" ? (
            <SalesForm
              setScreen={setScreen}
              campData={campData}
              setCampData={setCampData}
            />
          ) : screen === "view" ? (
            <SalesViewCamp
              setScreen={setScreen}
              setCampData={setCampData}
              campData={campData}
            />
          ) : screen === "partnerForm" ? (
            <PartnerForm
              setScreen={setScreen}
              partnerData={partnerData}
              setPartnerData={setPartnerData}
            />
          ) : screen === "partnerView" ? (
            <PartnerView
              setScreen={setScreen}
              userData={userData}
            />
          ) : screen === "partnerCreated" ? (
            <Confirmation/>
          ) :(
            <></>
          )}
          {screen === "otp" ? (
            ""
          ) : (
            <BottomNav setScreen={setScreen} screen={screen} />
          )}
        </>

        {/* phlebo code verification */}

        <SnackBar val={snackData} />
       {screen!=="otp"? <div style={{marginTop:150}}></div>:""}
      </div>
    </ThemeProvider>
  );
}
