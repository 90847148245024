import axios from 'axios';

const AXIOSV3:any = axios.create({
  baseURL: `${process.env.REACT_APP_ENV==="development"?process.env.REACT_APP_API_PATH:process.env.REACT_APP_API_PATH_V3}`,
  headers: {
    'Content-Type': 'application/json',
  },
  // timeout: 60000,
});

export default AXIOSV3;
