import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import StyledButton from "../components/Button/StyledButton";
import StyledButtonLight from "../components/Button/StyledButtonLight";
import {
  getCities,
  getCenterInfo,
  getCampCode,
  getMapMyIndiaAccessToken,
  createCamp,
  getPackageData,
} from "../services/actionSlice";
import { AppDispatch } from "../storage/store";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";

interface Props {
  setScreen: any;
  campData: any;
  setCampData: any;
}

const SalesForm: React.FC<Props> = ({ setScreen, campData, setCampData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const campCode = useSelector((state: any) => state.webAppSlice.campCode);
  const centerInfo = useSelector((state: any) => state.webAppSlice.centerInfo);
  const cities = useSelector((state: any) => state.webAppSlice.cities);
  const loading = useSelector((state: any) => state.webAppSlice.loading);
  const packageList = useSelector(
    (state: any) => state.webAppSlice.packageList
  );
  const mapmyindiaAccessToken = useSelector(
    (state: any) => state.webAppSlice.mapmyindiaAccessToken
  );
  const timer = useRef<any>(0);
  const [customerName, setCustomerName] = useState<any>("");
  const [customerNumber, setCustomerNumber] = useState<any>("");
  const [customerAge, setCustomerAge] = useState<any>("");
  const [customerGender, setCustomerGender] = useState<any>("");
  const [packageArray, setPackageArray] = useState<any>([]);
  const [disableBooking, setDisableBooking] = useState<boolean>(false);
  const [mobileValidation, setMobileValidation] = useState({
    mobileNumber: true,
    hr: true,
  });
  const [nameValidation, setNameValidation] = useState<any>(true);
  const [imgPackages, setImgPackages] = useState<any>([]);
  const [healthVital, setHealthVital] = useState<any>([]);
  const [expand, setExpand] = useState<any>(true);
  const [expandGeneral, setExpandGeneral] = useState<any>(false);
  const [expandAddr, setExpandAddr] = useState<any>(false);
  const [expandPkg, setExpandPkg] = useState<any>(false);
  const [expandRep, setExpandRep] = useState<any>(false);
  const [ageValidation, setAgeValidation] = useState<any>(true);
  const [center, setCentre] = useState<any>({
    id: "",
    name: "",
    display_name: null,
  });
  const [cityNames, setCityNames] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [activityType, setActivityType] = useState<any>("CCO");
  const [status, setStatus] = useState<any>("INACTIVE");
  const [campCodeInp, setCampCodeInp] = useState<any>("");
  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [hrMobileNumber, setHrMobileNumber] = useState<any>("");
  const [customerMax, setCustomerMax] = useState<any>("");
  const [customerMin, setCustomerMin] = useState<any>("");
  const [ownerName, setOwnerName] = useState<any>("");

  const [cityId, setCityId] = useState<any>({ id: "", name: "" });
  const [email, setEmail] = useState<string>("");
  const [hrEmail, setHrEmail] = useState<string>("");
  const [campAddress, setCampAddress] = React.useState("");
  const [spocName, setSpocName] = useState<string>("");
  const [hrName, setHrName] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [customerCharge, setCustomerCharge] = useState<any>("");
  const [targetBooking, setTargetBooking] = useState<any>("");
  const [targetRevenue, setTargetRevenue] = useState<any>("");
  const [expiryDate, setExpiryDate] = useState("");
  const [reportSmsSentTo, setReportSmsSentTo] = useState<any>("");
  const [reportHardCopy, setReportHardCopy] = useState<any>("");
  const [reportType, setReportType] = useState<any>("");
  const [emailReport, setEmailReport] = useState<any>("");
  const [digitalValue, setDigitalValue] = useState<any>("");
  const [paymentMode, setPaymentMode] = useState<any>("");
  const [amtByClient, setAmtByClient] = useState<any>("");
  const [contractDate, setContractDate] = useState<any>(
    moment().add(3, "days").format("YYYY-MM-DDT00:00:00")
  );
  const [emailValidation, setEmailValidation] = useState<any>({
    spoc: true,
    hr: true,
    report: true,
  });
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  const [configShow, setConfigShow] = useState<any>({
    has_audiometry: false,
    has_doctor_consultation: false,
    has_ecg: false,
    has_eye_vision: false,
    has_pft: false,
    has_xray: false,
    has_bmi: false,
    has_bp: false,
    has_pulse: false,
    has_hip: false,
  });
  useEffect(() => {
    dispatch(getCities({}));
    dispatch(getCampCode({}));
    dispatch(getCenterInfo({}));
    dispatch(getMapMyIndiaAccessToken());
  }, []);
  useEffect(() => {
    if (campCode?.code) {
      setCampCodeInp(campCode?.code);
    }
  }, [campCode]);
  const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) =>
    ["e", "E"].includes(e.key) && e.preventDefault();
  const verifyEmail = (type:any) => {
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (email&&type==="spoc" && !nameRegex.test(email.toString())) {
      setEmailValidation({...emailValidation,spoc:false});
    }
    if (hrEmail&&type==="hr" && !nameRegex.test(hrEmail.toString())) {
      setEmailValidation({...emailValidation,hr:false});

    }
    if (emailReport &&type==="report" && !nameRegex.test(emailReport.toString())) {
      setEmailValidation({...emailValidation,report:false});

    }
  };

  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "mob" &&
      mobileNumber &&
      !mobileRegEx.test(mobileNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        mobileNumber: false,
      }));
    }
  };
  const handleSubmitCentre = async (e: any) => {
    e.preventDefault();
    const formData: any = {
      code: campCodeInp,
      spoc_name: spocName,
      spoc_email: email,
      spoc_mobile: mobileNumber,
      date_of_activity_start: contractDate,
      date_of_activity_end: expiryDate,
      approved_on: null,
      activity_type: activityType,
      pincode: pincode,
      target_booking: targetBooking,
      target_revenue: targetRevenue,
      expected_number_max: customerMax,
      expected_number_min: customerMin,
      charge_per_customer: customerCharge,
      address: address,
      landmark: campAddress,
      report_email_sent_to: reportSmsSentTo,
      client_email: emailReport,
      owner: ownerName,
      center: center.id,
      city: cityId.id,
      packages:
        (packageArray.length > 0 && packageArray.map((val: any) => val.id)) ||
        [],
      package_filter: packageArray,
      approved_by: null,
      created_by: null,
      status: status,
      client_name: clientName,
      client_hr_name: hrName,
      client_hr_email: hrEmail,
      payment_mode: paymentMode,
      amount_paid_client: amtByClient ? amtByClient : 0,
      client_hr_number: hrMobileNumber,
      report_hard_copy: reportHardCopy === "yes" ? true : false,
      need_digital_report: digitalValue === "yes" ? true : false,
      report_type: reportType,
      report_email: emailReport,
      ...configShow,
    };

    await dispatch(createCamp(formData));
    setScreen("view");
  };

  const handleViewCamp = (data: any) => {
    data?.code && setCampCodeInp(data?.code || "");
    setClientName(data?.client_name || "");
    setHrName(data?.client_hr_name || "");
    setHrEmail(data?.client_hr_email || "");
    setEmailReport(data?.report_email || "");
    setCampAddress(data?.landmark || "");
    setHrMobileNumber(data?.client_hr_number || "");
    setReportType(data?.report_type || "");
    setReportHardCopy(data?.report_hard_copy ? "yes" : "no");
    setDigitalValue(data?.need_digital_report ? "yes" : "no");
    setActivityType(data?.activity_type || "CCO");
    setPincode(data?.pincode || "");
    setStatus(data?.status || "INACTIVE");
    setSpocName(data?.spoc_name || "");
    setEmail(data?.spoc_email || "");
    setMobileNumber(data?.spoc_mobile || "");
    setContractDate(
      data?.date_of_activity_start || moment().format("YYYY-MM-DDT00:00:00")
    );
    setExpiryDate(data?.date_of_activity_end || "");
    setTargetBooking(data?.target_booking || "");
    setTargetRevenue(data?.target_revenue || "");
    setCustomerMax(data?.expected_number_max || "");
    setCustomerMin(data?.expected_number_min || "");
    setCustomerCharge(data?.charge_per_customer || "");
    setAddress(data?.address || "");
    setPaymentMode(data?.payment_mode);
    setAmtByClient(data?.amount_paid_client || 0);
    setReportSmsSentTo(data?.report_email_sent_to || "");
    setEmailReport(data?.client_email || "");
    setOwnerName(data?.owner || "");

    setCentre({
      name: data?.center_details?.name || "",
      id: data?.center_details?.id || "",
      display_name: null,
    });
    setCityId(data?.city_details || { name: "", id: "" });
    setPackageArray(data?.package_filter || []);

    setConfigShow({
      has_audiometry: data?.has_audiometry || false,
      has_doctor_consultation: data?.has_doctor_consultation || false,
      has_ecg: data?.has_ecg || false,
      has_eye_vision: data?.has_eye_vision || false,
      has_pft: data?.has_pft || false,
      has_xray: data?.has_xray || false,

      has_bmi: data?.has_bmi || false,
      has_bp: data?.has_bp || false,
      has_pulse: data?.has_pulse || false,
      has_hip: data?.has_hip || false,
    });
    let radArr: any = [];
    radiology.map((val: any) => {
      if (data[val.value] === true) {
        radArr.push(val);
      }
    });
    setImgPackages(radArr);
    let hvArr: any = [];
    healthVitals.map((val: any) => {
      if (data[val.value] === true) {
        hvArr.push(val);
      }
    });
    setHealthVital(hvArr);
  };
  useEffect(() => {
    if (campData?.id) {
      handleViewCamp(campData);
    }
  }, [campData]);

  useEffect(() => {
    if (disableBooking) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setDisableBooking(false);
      }, 5000);
    }
  }, [disableBooking]);
  const radiology = [
    { name: "Audiometry", value: "has_audiometry" },
    { name: "Doctor Consultation", value: "has_doctor_consultation" },
    { name: "Eye vision", value: "has_eye_vision" },
    { name: "PFT", value: "has_pft" },
    { name: "X-Ray", value: "has_xray" },
    { name: "ECG", value: "has_ecg" },
  ];
  const healthVitals = [
    { name: "BMI", value: "has_bmi" },
    { name: "Blood Pressure", value: "has_bp" },
    { value: "has_pulse", name: "Pulse" },
    { value: "has_hip", name: "Hip Waist Ratio" },
  ];
  useEffect(() => {
    if (customerMax && customerMin && customerCharge) {
      setTargetBooking(Number(customerMax) * Number(customerCharge));
      setTargetRevenue(Number(customerMin) * Number(customerCharge));
    }
  }, [customerMax, customerMin, customerCharge]);
  //address
  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);
  useEffect(() => {
    if (mapAddressObject?.addressTokens?.city) {
      setCityNames(mapAddressObject?.addressTokens?.city);
    }
    if (mapAddressObject?.addressTokens?.pincode) {
      setPincode(mapAddressObject?.addressTokens?.pincode);
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (cityNames) {
      dispatch(getCities({ url: cityNames }));
    }
  }, [cityNames]);

  useEffect(() => {
    if (cityNames && cities && cities.length > 0) {
      cities.map((val: any) => {
        if (String(val.name) === String(cityNames)) {
          setCityId(val);
          setCityNames("");
        }
      });
    }
  }, [cities]);
  const updateConfig = (type: any, pkg: any) => {
    let imgObj: any = {
      has_audiometry: false,
      has_doctor_consultation: false,
      has_ecg: false,
      has_eye_vision: false,
      has_pft: false,
      has_xray: false,
    };
    let hvObj: any = {
      has_bmi: false,
      has_bp: false,
      has_pulse: false,
      has_hip: false,
    };
    if (type === "img") {
      radiology.map((val: any) =>
        pkg.length > 0
          ? pkg.map((x: any) => {
              if (val.value === x.value) {
                imgObj[x.value] = true;
              }
            })
          : ""
      );
      configShow.has_audiometry = imgObj.has_audiometry;
      configShow.has_doctor_consultation = imgObj.has_doctor_consultation;
      configShow.has_ecg = imgObj.has_ecg;
      configShow.has_eye_vision = imgObj.has_eye_vision;
      configShow.has_pft = imgObj.has_pft;
      configShow.has_xray = imgObj.has_xray;
    } else if ((type = "hv")) {
      healthVitals.map((val: any) =>
        pkg.length > 0
          ? pkg.map((x: any) => {
              if (val.value === x.value) {
                hvObj[x.value] = true;
              }
            })
          : ""
      );
      configShow.has_bmi = hvObj.has_bmi;
      configShow.has_bp = hvObj.has_bp;
      configShow.has_pulse = hvObj.has_pulse;
      configShow.has_hip = hvObj.has_hip;
    }
  };
  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        //@ts-ignore
        window[callbackName] = (data: any) => {
          //@ts-ignore
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  return (
    <form onSubmit={handleSubmitCentre}>
      <Box sx={{ flexGrow: 1 }}>
        {/* client info */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className={"dialogContent"}>
            <Grid item xs={10}>
              <Typography component="p">CLIENT INFORMATION</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expand ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpand(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpand(true)}
                />
              )}
            </Grid>
            {expand ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="title"
                    type="text"
                    label="CAMP Code"
                    value={campCodeInp}
                    disabled
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="center"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCentre(obj);
                      }
                    }}
                    value={center}
                    blurOnSelect
                    options={centerInfo?.results || []}
                    loading={loading}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) =>
                      (option?.display_name === null
                        ? option?.name
                        : option?.display_name) +
                      (option?.center_code
                        ? "(" + option?.center_code + ")"
                        : "")
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        dispatch(
                          getCenterInfo({
                            url: `optimize=true&name_or_code=${newInputValue}`,
                          })
                        );
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={"Select Partner Name"}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="title"
                    type="text"
                    label="Client name"
                    value={clientName}
                    onChange={(e) =>
                      setClientName(
                        String(e.target.value).replace(/[^a-zA-Z ]/g, "")
                      )
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="title"
                    type="text"
                    label="SPOC Name"
                    value={spocName}
                    onChange={(e) =>
                      setSpocName(
                        String(e.target.value).replace(/[^a-zA-Z ]/g, "")
                      )
                    }
                    required
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    type="email"
                    value={email}
                    size="small"
                    label="SPOC Email Address"
                    onBlur={() => verifyEmail("spoc")}
                    onFocus={() => {
                      emailValidation.spoc = true;
                      setEmailValidation(emailValidation);
                    }}
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                  />
                  {!emailValidation.spoc ? (
                    <sub style={{ color: "red" }}>
                      Please enter valid e-mail id
                    </sub>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="mobile_number"
                    type="number"
                    label="SPOC Mobile Number"
                    value={mobileNumber}
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) => setMobileNumber(e.target.value as String)}
                    required
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onBlur={() => verifyMobile("mob")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        mobileNumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.mobileNumber &&
                      "Incorrect Mobile Number"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="title"
                    type="text"
                    label="Client HR Name (Optional)"
                    value={hrName}
                    onChange={(e) =>
                      setHrName(
                        String(e.target.value).replace(/[^a-zA-Z ]/g, "")
                      )
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    type="email"
                    value={hrEmail}
                    size="small"
                    label="Client HR Email Address (Optional)"
                    onBlur={() => verifyEmail("hr")}
                    onFocus={() => {
                      emailValidation.hr = true;
                      setEmailValidation(emailValidation);
                    }}
                    variant="outlined"
                    onChange={(e) => setHrEmail(e.target.value as string)}
                    style={{ width: "100%" }}
                  />
                  {!emailValidation.hr ? (
                    <sub style={{ color: "red" }}>
                      Please enter valid e-mail id
                    </sub>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="mobile_number"
                    type="number"
                    label="Client HR Number (Optional)"
                    value={hrMobileNumber}
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setHrMobileNumber(e.target.value as String)
                    }
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onBlur={() => verifyMobile("mob")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        hr: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.hr && "Incorrect Mobile Number"
                    }
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* general info */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">GENERAL INFORMATION</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expandGeneral ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandGeneral(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandGeneral(true)}
                />
              )}
            </Grid>
            {expandGeneral ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={moment(contractDate).format("YYYY-MM-DDTHH:mm:ss")}
                    size="small"
                    label="Camp Start Date & Time"
                    variant="outlined"
                    required
                    inputProps={{
                      min: campData?.id
                        ? moment(campData?.date_of_activity_start).format(
                            "YYYY-MM-DDT00:00:00"
                          )
                        : moment().add(3, "days").format("YYYY-MM-DDT00:00:00"),
                      max: moment()
                        .add(30, "days")
                        .format("YYYY-MM-DDT00:00:00"),
                    }}
                    onChange={(e) => setContractDate(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={moment(expiryDate).format("YYYY-MM-DDTHH:mm:ss")}
                    size="small"
                    label="Camp End Date & Time"
                    variant="outlined"
                    required
                    inputProps={{
                      min: moment(contractDate).format("YYYY-MM-DDTHH:mm:ss"),
                      max: moment(contractDate)
                        .add(30, "days")
                        .format("YYYY-MM-DDTHH:mm:ss"),
                    }}
                    onChange={(e) => setExpiryDate(e.target.value as string)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="alt_number"
                    type="number"
                    label="Estimated Customers"
                    value={customerMax}
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      Number(e.target.value) >= 0
                        ? setCustomerMax(e.target.value as String)
                        : ""
                    }
                    onWheel={(e: any) => e.target.blur()}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="alt_number"
                    type="number"
                    label="Minimum Guarantee Customers"
                    value={customerMin}
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      Number(e.target.value) <= Number(customerMax)
                        ? setCustomerMin(e.target.value as String)
                        : ""
                    }
                    onWheel={(e: any) => e.target.blur()}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="partnerAddress"
                    type="number"
                    value={customerCharge}
                    size="small"
                    label="Charge Per Customer"
                    variant="outlined"
                    onChange={(e) =>
                      setCustomerCharge(e.target.value as string)
                    }
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="partnerAddress"
                    type="number"
                    value={targetBooking}
                    size="small"
                    aria-readonly
                    label="Camp Revenue"
                    variant="outlined"
                    onChange={(e) => setTargetBooking(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="partnerAddress"
                    type="number"
                    value={targetRevenue}
                    size="small"
                    aria-readonly
                    label="Minimum Guarantee Revenue"
                    variant="outlined"
                    onChange={(e) => setTargetRevenue(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    size="small"
                    value={paymentMode}
                    variant="outlined"
                    onChange={(e) => setPaymentMode(e.target.value)}
                    fullWidth
                    required
                    label="Payment Method"
                  >
                    <MenuItem value="PREP">Prepaid</MenuItem>
                    <MenuItem value="POST">Cash/Postpaid</MenuItem>
                    <MenuItem value="CO-P">CO Pay</MenuItem>
                  </TextField>
                </Grid>
                {paymentMode === "CO-P" || paymentMode === "POST" ? (
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      value={amtByClient}
                      size="small"
                      label="Enter amount to collect from camp"
                      variant="outlined"
                      onChange={(e) =>Number(e.target.value)<=customerCharge ?setAmtByClient(e.target.value):""}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* address */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">ADDRESS</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expandAddr ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandAddr(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandAddr(true)}
                />
              )}
            </Grid>
            {expandAddr ? (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="colony-area-sector"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                        setCityId({ id: "", name: "" });
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      `${option?.placeName}, ${option?.placeAddress}`
                    }
                    inputValue={address}
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue, event);
                      }, 500);
                      setAddress(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Enter your Location"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        size="small"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="city"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCityId(obj);
                      }
                    }}
                    options={cities || []}
                    value={cityId}
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => `${option.name}`}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        dispatch(getCities({ url: newInputValue }));
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select City"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="title"
                    type="text"
                    label="Camp Address"
                    value={campAddress}
                    onChange={(e) => setCampAddress(String(e.target.value))}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* package */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">PACKAGE DETAILS</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expandPkg ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandPkg(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandPkg(true)}
                />
              )}
            </Grid>
            {expandPkg ? (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="customerPackage"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue?.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push({
                            id: obj.id,
                            name: obj.name,
                            operator: "",
                            age: "",
                            gender: "",
                          });
                        });
                        setPackageArray(packs);
                      }
                    }}
                    multiple
                    value={packageArray}
                    disableClearable
                    loading={loading}
                    options={packageList?.results || []}
                    limitTags={0}
                    disabled={!center?.id || !cityId?.id}
                    freeSolo
                    disableCloseOnSelect
                    aria-required
                    onInputChange={(event, newInputValue) => {
                      dispatch(
                        getPackageData({
                          url: `?center=${center.id}&cityid=${cityId?.id}&name=${newInputValue}`,
                        })
                      );
                    }}
                    getOptionLabel={(option: any) => {
                      return option?.name;
                    }}
                    getOptionDisabled={(option: any) => {
                      const packID = packageArray.map((pack: any) => pack.id);
                      return packID?.includes(option?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Pathology Package"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="customerPackage"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue?.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj);
                        });
                        setImgPackages(packs);
                        updateConfig("img", packs);
                      }
                    }}
                    multiple
                    disableClearable
                    options={radiology}
                    limitTags={2}
                    freeSolo
                    disableCloseOnSelect
                    aria-required
                    value={imgPackages}
                    getOptionLabel={(option: any) => {
                      return option?.name;
                    }}
                    getOptionDisabled={(option: any) => {
                      const packID =
                        (imgPackages.length > 0 &&
                          imgPackages.map((pack: any) => pack.value)) ||
                        [];
                      return packID?.includes(option?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Radiology Package"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="customerPackage"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue?.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj);
                        });
                        setHealthVital(packs);
                        updateConfig("hv", packs);
                      }
                    }}
                    multiple
                    disableClearable
                    options={healthVitals}
                    limitTags={2}
                    freeSolo
                    disableCloseOnSelect
                    aria-required
                    value={healthVital}
                    getOptionLabel={(option: any) => {
                      return option?.name;
                    }}
                    getOptionDisabled={(option: any) => {
                      const packID =
                        (healthVital.length > 0 &&
                          healthVital.map((pack: any) => pack.value)) ||
                        [];
                      return packID?.includes(option?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Health Vitals"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* report */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">REPORT CONFIGURATION</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expandRep ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandRep(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandRep(true)}
                />
              )}
            </Grid>
            {expandRep ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    select
                    size="small"
                    value={reportSmsSentTo}
                    variant="outlined"
                    style={{ height: "40px" }}
                    onChange={(e) => setReportSmsSentTo(e.target.value)}
                    fullWidth
                    label="Report Sent To"
                  >
                    <MenuItem value="CL">Client</MenuItem>
                    <MenuItem value="CU">Customer</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    size="small"
                    value={reportHardCopy}
                    variant="outlined"
                    style={{ height: "40px" }}
                    onChange={(e) => setReportHardCopy(e.target.value)}
                    fullWidth
                    label="Report Hard Copy"
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    size="small"
                    value={reportType}
                    variant="outlined"
                    style={{ height: "40px" }}
                    onChange={(e) => setReportType(e.target.value)}
                    fullWidth
                    label="Report Type"
                  >
                    <MenuItem value="S">Smart</MenuItem>
                    <MenuItem value="N">Normal</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    size="small"
                    value={digitalValue}
                    variant="outlined"
                    style={{ height: "40px" }}
                    onChange={(e) => setDigitalValue(e.target.value)}
                    fullWidth
                    label="Digital Value"
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    type="email"
                    value={emailReport}
                    size="small"
                    label="Report Email Address"
                    onBlur={() => verifyEmail("report")}
                    onFocus={() => {
                      emailValidation.report = true;
                      setEmailValidation(emailValidation);
                    }}
                    disabled={reportSmsSentTo === "CU"}
                    variant="outlined"
                    onChange={(e) => setEmailReport(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                  />
                  {!emailValidation.report ? (
                    <sub style={{ color: "red" }}>
                      Please enter valid e-mail id
                    </sub>
                  ) : (
                    ""
                  )}
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* button */}
        {campData?.id ? (
          <div style={{ margin: "0 15px 15px" }}>
            <StyledButtonLight onClick={() => setScreen("view")}>
              BACK
            </StyledButtonLight>
          </div>
        ) : (
          <>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButton
                type="submit"
                disabled={
                  disableBooking ||
                  !center?.id ||
                  !spocName ||
                  !email ||
                  !mobileNumber ||
                  !contractDate ||
                  !expiryDate ||
                  !customerMax ||
                  !customerMin ||
                  !customerCharge ||
                  !targetBooking ||
                  !targetRevenue ||
                  !cityId?.id ||
                  packageArray.length === 0 ||
                  (reportSmsSentTo !== "CU" && !emailReport)
                }
              >
                SUBMIT
              </StyledButton>
              {customerGender !== "" && customerAge !== "" ? (
                <sub style={{ color: "red" }}>
                  No package is configure for mentioned details.
                </sub>
              ) : (
                ""
              )}
            </div>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButtonLight
                onClick={() => {
                  setCampData({});
                  setScreen("menu");
                }}
              >
                BACK
              </StyledButtonLight>
            </div>
          </>
        )}
      </Box>
    </form>
  );
};

export default SalesForm;
