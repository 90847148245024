import React, { useState } from "react";
import { useRef } from "react";
import { AppDispatch } from "../../storage/store";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  MenuItem,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import {
  getCities,
  createCamp,
  getCamp,
  getCampCode,
  getMapMyIndiaAccessToken,
} from "../../services/actionSlice";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import SecureStorage from "../../config/SecureStorage";
import SnackBar from "../../components/SnackBar/snackBarPopPup";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E00646",
    },
    secondary: {
      main: "#2677F0",
    },
  },
});
const CreateCampPage = () => {
  const timer = useRef<any>(0);
  const campCode = useSelector((state: any) => state.webAppSlice.campCode);
  const cities = useSelector((state: any) => state.webAppSlice.cities);
  const campList = useSelector((state: any) => state.webAppSlice.campList);
  const loading = useSelector((state: any) => state.webAppSlice.loading);
  const snackData = useSelector((state: any) => state.webAppSlice.snackbarData);

  const campCreated = useSelector(
    (state: any) => state.webAppSlice.campCreated
  );

  const mapmyindiaAccessToken = useSelector(
    (state: any) => state.webAppSlice.mapmyindiaAccessToken
  );

  const dispatch = useDispatch<AppDispatch>();
  const [labName, setLabName] = React.useState<any>("");
  const [center, setCentre] = useState<any>({
    id: "",
    name: "",
    display_name: null,
  });
  const [campId, setCampId] = useState<any>();
  const [cityNames, setCityNames] = useState<string>("");
  const [campUploadId, setCampUploadId] = useState<any>("");
  const [pincode, setPincode] = useState<string>("");
  const [doctorName, setDoctorName] = useState<any>("");
  const [activityType, setActivityType] = useState<any>("CCO");
  const [status, setStatus] = useState<any>("ACTIVE");
  const [paymentMode, setPaymentMode] = useState<any>("");
  const [perCustomerAmt, setPerCustomerAmt] = useState<any>("");
  const [amtByClient, setAmtByClient] = useState<any>("");
  const [campCodeInp, setCampCodeInp] = useState<any>("");
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryTime, setExpiryTime] = useState("00");
  const [salesManager, setSalesManager] = useState<string>("");
  const [contractDate, setContractDate] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const [contractTime, setContractTime] = useState<string>("00");

  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [customerMax, setCustomerMax] = useState<any>("");
  const [customerMin, setCustomerMin] = useState<any>("");
  const [ownerName, setOwnerName] = useState<any>("");

  const [cityId, setCityId] = useState<any>({ id: "", name: "" });
  const [email, setEmail] = React.useState("");
  const [emailReport, setEmailReport] = React.useState("");
  const [spocName, setSpocName] = React.useState("");
  const [address, setAddress] = useState<string>("");
  const [customerCharge, setCustomerCharge] = useState<any>("");
  const [targetBooking, setTargetBooking] = useState<any>("");
  const [targetRevenue, setTargetRevenue] = useState<any>("");
  const [messagesTemplates, setMessagesTemplates] = useState<any>([]);
  const [messagesTemplatesDef, setMessagesTemplatesDef] = useState<any>([]);
  const [otpThrough, setOtpThrough] = useState<any>("");
  const [reportSmsSentTo, setReportSmsSentTo] = useState<any>("");
  const [haveUserData, setHaveUserData] = useState<any>("no");
  const [packageAddon, setPackageAddon] = useState<any>("");
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  const [hrName, setHrName] = useState<string>("");
  const [hrEmail, setHrEmail] = useState<string>("");
  const [hrMobileNumber, setHrMobileNumber] = useState<any>("");
  const [digitalValue, setDigitalValue] = useState<any>("");
  const [reportHardCopy, setReportHardCopy] = useState<any>("");
  const [checkedOne, setCheckedOne] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [labId, setLabId] = useState<any>("");
  const [colChargePd, setColChargePd] = useState<any>("");
  const [configShow, setConfigShow] = useState<any>({
    has_audiometry: false,
    has_doctor_consultation: false,
    has_ecg: false,
    has_eye_vision: false,
    has_pft: false,
    has_xray: false,
    has_bmi: false,
    has_bp: false,
    has_pulse: false,
    has_hip: false,
  });

  const [internalTeam, setInternalTeam] = useState<any>([
    {
      user_group: "",
      user_email: [],
    },
  ]);
  const [packageArray, setPackageArray] = useState<any>([
    {
      id: "",
      name: "",
      operator: "",
      age: "",
      gender: "",
      fasting_required: false,
      // has_urine: false,
    },
    {
      id: "",
      name: "",
      operator: "",
      age: "",
      gender: "",
      fasting_required: true,
      // has_urine: false,
    },
  ]);
  const [phleboArray, setPhleboArray] = useState<any>([
    {
      package: "Pathology",
      phlebo: [],

      phlebo_type: "",
      phlebo_no: "",
      phlebo_amt: "",
      phlebo_upi: "",
      is_active: true,
    },
    {
      package: "Health Vitals",
      phlebo: [],

      phlebo_type: "",
      phlebo_no: "",
      phlebo_amt: "",
      phlebo_upi: "",
      is_active: true,
    },
    {
      package: "Audiometry",
      phlebo: [],

      phlebo_type: "",
      phlebo_no: "",
      phlebo_amt: "",
      phlebo_upi: "",
      is_active: configShow?.has_xray,
    },
    {
      package: "Doctor Consultation",
      phlebo: [],

      phlebo_type: "",
      phlebo_no: "",
      phlebo_amt: "",
      phlebo_upi: "",
      is_active: configShow?.has_doctor_consultation,
    },
    {
      package: "Eye vision",
      phlebo: [],

      phlebo_type: "",
      phlebo_no: "",
      phlebo_amt: "",
      phlebo_upi: "",
      is_active: configShow?.has_eye_vision,
    },
    {
      package: "PFT",
      phlebo: [],

      phlebo_type: "",
      phlebo_no: "",
      phlebo_amt: "",
      phlebo_upi: "",
      is_active: configShow.has_pft,
    },
    {
      package: "X-Ray",
      phlebo: [],

      phlebo_type: "",
      phlebo_no: "",
      phlebo_amt: "",
      phlebo_upi: "",
      is_active: configShow.has_xray,
    },
    {
      package: "ECG",
      phlebo: [],

      phlebo_type: "",
      phlebo_no: "",
      phlebo_amt: "",
      phlebo_upi: "",
      is_active: configShow.has_ecg,
    },
  ]);

  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [mobileValidation, setMobileValidation] = useState({
    mobileNumber: true,
    hr: true,
  });
  const [centerShow, setCentreShow] = useState<boolean>(false);
  const [hasUrine, setHasUrine] = useState<boolean>(false);
  const [imgPackages, setImgPackages] = useState<any>([]);
  const [healthVital, setHealthVital] = useState<any>([]);
  const [editButton, setEditButton] = useState<any>(false);
  const [submitted, setSubmitted] = useState<any>(false);
  const [token, setToken] = useState<any>(false);
  const [campType, setCampType] = useState<any>("");

  const radiology = [
    { name: "Audiometry", value: "has_audiometry" },
    { name: "Doctor Consultation", value: "has_doctor_consultation" },
    { name: "Eye vision", value: "has_eye_vision" },
    { name: "PFT", value: "has_pft" },
    { name: "X-Ray", value: "has_xray" },
    { name: "ECG", value: "has_ecg" },
  ];
  const healthVitals = [
    { name: "BMI", value: "has_bmi" },
    { name: "Blood Pressure", value: "has_bp" },
    { value: "has_pulse", name: "Pulse" },
    { value: "has_hip", name: "Hip Waist Ratio" },
  ];

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const partner_id = params.get("pid");
    const token = params.get("tk");
    if (partner_id) {
      setCampUploadId(partner_id);
    }
    if (token) {
      SecureStorage.setItem("tokenNew", token);
      setToken(true);
    }
  }, [window.location.search]);

  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "mob" &&
      mobileNumber &&
      !mobileRegEx.test(mobileNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        mobileNumber: false,
      }));
    }
    if (
      type === "hr" &&
      hrMobileNumber &&
      !mobileRegEx.test(hrMobileNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        hr: false,
      }));
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(getCities({}));
      dispatch(getCampCode({}));
      dispatch(getCamp({ url: `id=${campUploadId}` }));
      dispatch(getMapMyIndiaAccessToken());
    }
  }, [token]);
  useEffect(() => {
    if (campCode?.code) {
      setCampCodeInp(campCode?.code);
    }
  }, [campCode]);
  const handleEditCamp = (data: any, duplicate: boolean) => {
    duplicate === false && setDisableEdit(true);
    duplicate === false && setEditButton(true);
    duplicate === false && setCampId(data.id);
    duplicate === false && setCampCodeInp(data?.code);
    setActivityType(data?.activity_type);
    setPincode(data?.pincode);
    duplicate === false && setStatus(data?.status);

    setTargetBooking(data?.target_booking);
    setTargetRevenue(data?.target_revenue);
    setCustomerMax(data?.expected_number_max);
    setCustomerMin(data?.expected_number_min);
    setCustomerCharge(data?.charge_per_customer);
    setReportSmsSentTo(data?.report_email_sent_to);
    setLabId(data?.lab?.id);
    setLabName(data?.lab?.name || "");
    setEmailReport(data?.client_email);
    setOwnerName(data?.owner);
    setHaveUserData(data?.have_user_data === true ? "yes" : "no");
    setOtpThrough(data?.verify_via);
    setCentre({
      name: data?.center_details?.name || "",
      id: data?.center_details?.id || "",
      display_name: null,
    });
    // setCityId(data?.city_details || { name: "", id: "" });
    setPackageArray(data?.package_filter);
    setPackageAddon(data?.can_addon === true ? "yes" : "no");
    // setDoctorName(data?.doctor_name);
    setPaymentMode(data?.payment_mode);
    setCheckedOne(data?.voucher_code_required);
    setSalesManager(data?.sales_user_details?.user_name || "");
    setColChargePd(data?.collection_charges_day || "");
    // setIsTemplate(data?.is_template);
    setHasUrine(data?.has_urine);
    let arrtc: any = [
      {
        user_group: "",
        user_email: [],
      },
    ];
    if (
      data?.team_config &&
      data?.team_config.length > 0 &&
      Array.isArray(data?.team_config[0]?.user_email)
    ) {
      setInternalTeam(data?.team_config);
    } else {
      setInternalTeam(arrtc);
    }

    setPerCustomerAmt(data?.per_customer_amount || 0);
    setAmtByClient(data?.amount_paid_client || 0);
    // setMessagesSentThrough(data?.send_on || "");
    setMessagesTemplates(
      (data?.sms_templates &&
        data?.sms_templates?.length > 0 &&
        data?.sms_templates.map((val: any) => val.id)) ||
        []
    );
    setMessagesTemplatesDef(data?.sms_templates || []);
    setConfigShow({
      has_audiometry: data?.has_audiometry,
      has_doctor_consultation: data?.has_doctor_consultation,
      has_ecg: data?.has_ecg,
      has_eye_vision: data?.has_eye_vision,
      has_pft: data?.has_pft,
      has_xray: data?.has_xray,

      has_bmi: data?.has_bmi,
      has_bp: data?.has_bp,
      has_pulse: data?.has_pulse,
      has_hip: data?.has_hip,
    });
    let radArr: any = [];
    radiology.map((val: any) => {
      if (data[val.value] === true) {
        radArr.push(val);
      }
    });
    setImgPackages(radArr);
    let hvArr: any = [];
    healthVitals.map((val: any) => {
      if (data[val.value] === true) {
        hvArr.push(val);
      }
    });
    setHealthVital(hvArr);
    let arr = [...phleboArray];
    data?.phlebo_config &&
      data?.phlebo_config.length > 0 &&
      data?.phlebo_config.map((val: any) =>
        arr.map((val1: any, index: any) => {
          if (val.package === val1.package) {
            arr[index].phlebo = val.phlebo_name
              ? [{ id: val.phlebo_id, name: val.phlebo_name }]
              : val.phlebo;
            arr[index].phlebo_type = val.phlebo_type;
            arr[index].phlebo_no = val.phlebo_no;
            arr[index].phlebo_amt = val.phlebo_amt;
            arr[index].phlebo_upi = val?.phlebo_upi;
          }
        })
      );
    // setHrName(data?.client_hr_name || "");
    // setHrEmail(data?.client_hr_email || "");
    // setHrMobileNumber(data?.client_hr_number || "");
    setReportHardCopy(data?.report_hard_copy ? "yes" : "no");
    setDigitalValue(data?.need_digital_report ? "yes" : "no");
    setCampType(data?.camp_type || "");
    setPhleboArray(arr);
    setCentreShow(true);
  };
  const handleSubmitCentre = async (e: any) => {
    setSubmitted(false);
    e.preventDefault();
    const formData: any = {
      code: campCodeInp,
      spoc_name: spocName,
      spoc_email: email,
      spoc_mobile: mobileNumber,
      date_of_activity_start: contractDate + `T${contractTime}:00:00+05:30`,
      date_of_activity_end: expiryDate + `T${expiryTime}:00:00+05:30`,
      approved_on: null,
      activity_type: activityType,
      pincode: pincode,
      target_booking: targetBooking,
      target_revenue: targetRevenue,
      expected_number_max: customerMax,
      expected_number_min: customerMin,
      charge_per_customer: customerCharge,
      address: address,
      report_email_sent_to: reportSmsSentTo,
      client_email: emailReport,
      owner: ownerName,
      have_user_data: haveUserData === "yes" ? true : false,
      verify_via: otpThrough,
      center: center.id,
      city: cityId.id,
      packages:
        (packageArray.length > 0 && packageArray.map((val: any) => val.id)) ||
        [],
      package_filter: packageArray,
      approved_by: null,
      created_by: null,
      can_addon: packageAddon === "yes" ? true : false,
      doctor_name: doctorName,
      payment_mode: paymentMode,
      team_config: internalTeam,
      status: status,
      per_customer_amount: perCustomerAmt ? perCustomerAmt : 0,
      amount_paid_client: amtByClient ? amtByClient : 0,
      phlebo_config: phleboArray.filter((val: any) => val.is_active === true),
      communication: messagesTemplates,
      client_hr_name: hrName,
      client_hr_email: hrEmail,
      client_hr_number: hrMobileNumber,
      report_hard_copy: reportHardCopy === "yes" ? true : false,
      need_digital_report: digitalValue === "yes" ? true : false,
      camp_type: campType,
      sales_user_details: salesManager,
      collection_charges_day: colChargePd,
      is_template: isTemplate,
      has_urine: hasUrine,
      processing_location: labId,
      ...configShow,
    };
    if (!salesManager) {
      delete formData.sales_user_details;
    }

    await dispatch(createCamp(formData));
    setEditButton(false);
    setCentreShow(false);
  };

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);
  useEffect(() => {
    if (mapAddressObject?.addressTokens?.city) {
      setCityNames(mapAddressObject?.addressTokens?.city);
    }
    if (mapAddressObject?.addressTokens?.pincode) {
      setPincode(mapAddressObject?.addressTokens?.pincode);
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (cityNames) {
      dispatch(getCities(cityNames));
    }
  }, [cityNames]);

  useEffect(() => {
    if (cityNames && cities && cities.length > 0) {
      cities.map((val: any) => {
        if (String(val.name) === String(cityNames)) {
          setCityId(val);
          setCityNames("");
        }
      });
    }
  }, [cities]);
  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        //@ts-ignore
        window[callbackName] = (data: any) => {
          //@ts-ignore
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };

  useEffect(() => {
    if (customerMax && customerMin && customerCharge) {
      setTargetBooking(Number(customerMax) * Number(customerCharge));
      setTargetRevenue(Number(customerMin) * Number(customerCharge));
    }
  }, [customerMax, customerMin, customerCharge]);
  useEffect(() => {
    if (paymentMode === "PREP") {
      setPackageAddon("no");
    }
  }, [paymentMode]);
  useEffect(() => {
    if (otpThrough === "E") {
      setHaveUserData("yes");
    }
  }, [otpThrough]);
  const [emailValidation, setEmailValidation] = useState<any>({
    spoc: true,
    report: true,
    hr: true,
  });

  const verifyEmail = () => {
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (email && !nameRegex.test(email.toString())) {
      emailValidation.spoc = false;
    }
    if (emailReport && !nameRegex.test(emailReport.toString())) {
      emailValidation.report = false;
    }
    if (hrEmail && !nameRegex.test(hrEmail.toString())) {
      emailValidation.hr = false;
    }
    setEmailValidation(emailValidation);
  };
  useEffect(() => {
    if (campList?.results && campList?.results.length > 0) {
      handleEditCamp(campList?.results[0], true);
    }
  }, [campList]);
  useEffect(() => {
    if (campCreated?.id) {
      setSubmitted(true);
    }
  }, [campCreated]);

  return (
    <ThemeProvider theme={theme}>
      <div id="createCamp" className="containerMain" style={{ padding: 20 }}>
        {submitted ? (
          <div style={{ textAlign: "center", marginTop: "30vh" }}>
            <h3>Camp Created</h3>
            <p>
              <b>Camp code: {campCreated?.code}</b>
            </p>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => window.location.reload()}
            >
              Back to camp creation
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSubmitCentre} style={{ margin: "10px 0px" }}>
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "left" }}
            >
              <Grid item xs={12}>
                <h3 style={{ color: "#E00646" }}>Create Camp</h3>
                <hr style={{ border: "1px solid #2677f0" }} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  name="title"
                  type="text"
                  label="CAMP Code"
                  value={campCodeInp}
                  disabled
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size="small"
                  name="title"
                  type="text"
                  label="Client Camp SPOC Name"
                  value={spocName}
                  onChange={(e) =>
                    setSpocName(
                      String(e.target.value).length <= 30
                        ? String(e.target.value).replace(/[^a-zA-Z ]/g, "")
                        : spocName
                    )
                  }
                  required
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  type="email"
                  value={email}
                  size="small"
                  label="Client Camp SPOC Email"
                  onBlur={() => verifyEmail()}
                  onFocus={() => {
                    emailValidation.spoc = true;
                    setEmailValidation(emailValidation);
                  }}
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
                {!emailValidation.spoc ? (
                  <sub style={{ color: "red" }}>
                    Please enter valid e-mail id
                  </sub>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  name="mobile_number"
                  type="number"
                  label="Client Camp SPOC Mobile Number"
                  value={mobileNumber}
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) => setMobileNumber(e.target.value as String)}
                  required
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onBlur={() => verifyMobile("mob")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      mobileNumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.mobileNumber && "Incorrect Mobile Number"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  name="title"
                  type="text"
                  label="Doctor Name - Doctor Code (Optional)"
                  value={doctorName}
                  onChange={(e) =>
                    setDoctorName(
                      String(e.target.value).length <= 30
                        ? String(e.target.value).replace(/[^a-zA-Z ]/g, "")
                        : doctorName
                    )
                  }
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TextField
                  size="small"
                  name="title"
                  type="text"
                  label="Client SPOC2 Name (Optional)"
                  value={hrName}
                  onChange={(e) =>
                    setHrName(
                      String(e.target.value).length <= 30
                        ? String(e.target.value).replace(/[^a-zA-Z ]/g, "")
                        : hrName
                    )
                  }
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TextField
                  name="email"
                  type="email"
                  value={hrEmail}
                  size="small"
                  label="Client SPOC2 Email Address (Optional)"
                  onBlur={() => verifyEmail()}
                  onFocus={() => {
                    emailValidation.hr = true;
                    setEmailValidation(emailValidation);
                  }}
                  variant="outlined"
                  onChange={(e) => setHrEmail(e.target.value as string)}
                  style={{ width: "100%" }}
                />
                {!emailValidation.hr ? (
                  <sub style={{ color: "red" }}>
                    Please enter valid e-mail id
                  </sub>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  name="mobile_number"
                  type="number"
                  label="Client SPOC2 Number (Optional)"
                  value={hrMobileNumber}
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) => setHrMobileNumber(e.target.value as String)}
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onBlur={() => verifyMobile("hr")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      hr: true,
                    }));
                  }}
                  helperText={!mobileValidation.hr && "Incorrect Mobile Number"}
                />
              </Grid>

              <Grid item xs={12} style={{ display: "flex" }}>
                <TextField
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(contractDate).format("YYYY-MM-DD")}
                  size="small"
                  label="Camp Start Date & Time"
                  variant="outlined"
                  required
                  inputProps={{
                    min: editButton ? "" : moment().format("YYYY-MM-DD"),
                    max: editButton
                      ? moment(contractDate)
                          .add(30, "days")
                          .format("YYYY-MM-DD")
                      : moment().add(30, "days").format("YYYY-MM-DD"),
                  }}
                  onChange={(e) => setContractDate(e.target.value)}
                  style={{ width: "75%" }}
                />
                <TextField
                  select
                  size="small"
                  value={contractTime}
                  variant="outlined"
                  label="HH"
                  style={{ height: "40px", width: "25%" }}
                  onChange={(e) => setContractTime(e.target.value)}
                  fullWidth
                  required
                >
                  {Array.from(Array(25).keys()).map((val: any) => {
                    return (
                      <MenuItem
                        value={String(val).length === 1 ? `0${val}` : val}
                      >
                        {String(val).length === 1 ? `0${val}` : val}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <TextField
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(expiryDate).format("YYYY-MM-DD")}
                  size="small"
                  label="Camp End Date & Time"
                  variant="outlined"
                  required
                  inputProps={{
                    min: moment(contractDate).format("YYYY-MM-DD"),
                    max: moment(contractDate)
                      .add(30, "days")
                      .format("YYYY-MM-DD"),
                  }}
                  onChange={(e) => setExpiryDate(e.target.value as string)}
                  style={{ width: "75%" }}
                />
                <TextField
                  select
                  size="small"
                  value={expiryTime}
                  variant="outlined"
                  label="HH"
                  required
                  style={{ height: "40px", width: "25%" }}
                  onChange={(e) => setExpiryTime(e.target.value)}
                  fullWidth
                >
                  {Array.from(Array(25).keys()).map((val: any) => {
                    return (
                      <MenuItem
                        value={String(val).length === 1 ? `0${val}` : val}
                      >
                        {String(val).length === 1 ? `0${val}` : val}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  id="colony-area-sector"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setMapAddressObject(obj);
                      setCityId({ id: "", name: "" });
                    }
                  }}
                  options={suggestedAddress ? suggestedAddress : []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) =>
                    `${option?.placeName}, ${option?.placeAddress}`
                  }
                  inputValue={address}
                  filterOptions={(option: any) => option}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      locationAutoCompleteSearch(newInputValue, event);
                    }, 500);
                    setAddress(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Address"
                      label="Address"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      required
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="city"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCityId(obj);
                    }
                  }}
                  options={cities || []}
                  value={cityId}
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => `${option.name}`}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      dispatch(getCities(newInputValue));
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select City"
                      variant="outlined"
                      required
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={
                    !campCodeInp||
                    !mobileValidation.hr ||
                    !mobileValidation.mobileNumber ||
                    loading
                  }
                >
                  Create Camp
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => window.location.reload()}
                  style={{marginBottom:30}}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
      <SnackBar val={snackData} />
    </ThemeProvider>
  );
};
export default CreateCampPage;
