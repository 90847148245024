import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import WebAppComponent from "../container/Web-app-Component";
import SalesWebAppComponent from "../container/SalesWebApp";
import CampCreateComponent from "../container/CampCreate";

function WebAppRoutes(props: any) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WebAppComponent />} />
        <Route path="/sales" element={<SalesWebAppComponent />} />
        <Route path="/create-camp/" element={<CampCreateComponent />} />
      </Routes>
    </BrowserRouter>
  );
}
export default connect(null, {})(WebAppRoutes);
