import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import StyledButton from "../components/Button/StyledButton";
import StyledButtonLight from "../components/Button/StyledButtonLight";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  updateCenter,
  createNewCenter,
  getCentreCode,
  getIdentityProof,
  getPinCode,
  getBankName,
  getMessageTemplates,
  getWhatsappTemplates,
} from "../services/actionSlice";
import { AppDispatch } from "../storage/store";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import moment from "moment";

interface Props {
  setScreen: any;
  partnerData: any;
  setPartnerData: any;
}

const SalesForm: React.FC<Props> = ({
  setScreen,
  partnerData,
  setPartnerData,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const centreCode = useSelector((state: any) => state.webAppSlice.centreCode);
  const identityProofData = useSelector(
    (state: any) => state.webAppSlice.identityProofData
  );
  const pin_code = useSelector((state: any) => state.webAppSlice.pin_code);

  const bankName = useSelector((state: any) => state.webAppSlice.bankName);
  const messageTemplate = useSelector(
    (state: any) => state.webAppSlice.messageTemplate
  );
  const whatsappTemplate = useSelector(
    (state: any) => state.webAppSlice.whatsappTemplate
  );
  const partnerCreated = useSelector(
    (state: any) => state.webAppSlice.partnerCreated
  );
  const loading = useSelector((state: any) => state.webAppSlice.loading);

  const timer = useRef<any>(0);
  const [expand, setExpand] = useState<any>(true);
  const [expandGeneral, setExpandGeneral] = useState<any>(false);
  const [expandAddr, setExpandAddr] = useState<any>(false);
  const [expandPkg, setExpandPkg] = useState<any>(false);
  const [expandRep, setExpandRep] = useState<any>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [legalName, setLegalName] = useState<any>("");
  const [centerId, setCenterId] = useState<any>();
  const [centreAccred, setCentreAccred] = useState<any>("");
  const [whatsappTemplates, setWhatsappTemplates] = useState<any>([]);
  const [whatsappTemplatesDef, setWhatsappTemplatesDef] = useState<any>([]);
  const [centerName, setCentreName] = useState<any>("");
  const [centerType, setCentreType] = useState<any>("");
  const [salesManagerText, setSalesManagerText] = useState<any>("");
  // const [patientType, setPaitentType] = useState<any>("")
  const [identityProof, setIdentityProof] = useState<any>("");
  const [centerCode, setCentreCode] = useState<any>("");
  const [expiryDate, setExpiryDate] = useState("");
  const [contractDate, setContractDate] = useState("");
  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [centerKey, setCenterKey] = useState<any>("");
  const [altNumber, setaltNumber] = useState<any>("");
  const [ownerName, setOwnerName] = useState<any>("");
  const [centerAddress, setCentreAddress] = React.useState("");
  const [cityName, setCityName] = useState<any>("");
  const [cityId, setCityId] = React.useState<any>();
  const [pincode, setPinCode] = React.useState<any>();
  const [nationality, setNationality] = React.useState("Indian");
  const [centerHeadalterNateNumber, setCentreHeadAlternateNumber] =
    useState<any>();
  const [centerHeadalContactNumber, setCentreHeadContactNumber] =
    useState<any>();
  const [isAgreement, setIsAgreement] = useState<boolean>(false);
  const [email, setEmail] = React.useState("");
  const [agreementFile, setAgreementFile] = useState<any>([]);
  const [agreementLink, setAgreementLink] = useState<any>("");
  const [adharNumber, setAdharNumber] = React.useState<any>("");
  const [fileReset, setFileReset] = useState<any>("1");
  const [area, setArea] = React.useState<String>("");
  const [securityAmount, setSecurityAmount] = React.useState("");
  const [serviceRateCategory, setServiceRateCategory] = React.useState<any>("");
  const [serviceRateCategoryDef, setServiceRateCategoryDef] =
    React.useState<any>("");
  const [barCodeType, setBarCodeType] = React.useState<any>([]);
  const [tag_ProccessingLab, setTag_ProccessingLab] = React.useState("");
  const [categoryName, setCategoryName] = useState<any>("");
  const [bookingLimit, setBookingLimit] = useState<any>("");
  const [bankAccountHolder, setbankAccountHolder] = React.useState("");
  const [bank, setBank] = React.useState("");
  const [bankInput, setBankInput] = React.useState("");
  const [gstinNumber, setGstinNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [ifscCode, setIfscCode] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [center_longitude, setCentreLongitude] = useState<any>("");
  const [center_latitude, setCentreLatitude] = useState<any>("");
  const [designation, setDesignation] = useState<any>("");
  const [messagesTemplates, setMessagesTemplates] = useState<any>([]);
  const [messagesTemplatesDef, setMessagesTemplatesDef] = useState<any>([]);
  const [messagesSentThrough, setMessagesSentThrough] = useState<any>("both");
  // const [reportWhatsappSentTo, setReportWhatsappSentTo] = useState<any>("none");
  const [reportEmailSentTo, setReportEmailSentTo] = useState<any>("none");
  const [bookingSms, setBookingSms] = useState<any>("");
  const [bookingEmail, setBookingEmail] = useState<any>("none");
  const [packageRights, setPackageRights] = useState<any>("redcliffe");
  const [pinCodeId, setPincodeId] = useState<any>("");
  const [pinCodeIdDef, setPincodeIdDef] = useState<any>("");
  const [googleLocation, setGoogleLocation] = useState<any>("");
  const [file, setFile] = React.useState<any>([]);
  const [fileGst, setFileGst] = React.useState<any>([]);
  const [checked, setChecked] = React.useState(false);
  const [cId, setCid] = useState<any>(0);
  const [addressStatus, setAddressStatus] = useState<any>("confirm");
  const [checkedOne, setCheckedOne] = useState<boolean>(false);
  const [withoutHeaderReport, setWithoutHeaderReport] =
    useState<boolean>(false);
  const [sourceType, setSourceType] = useState<any>("");
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [smsType, setSmsType] = React.useState({
    mail: false,
    sms: false,
    screen: false,
    isbillinglock: false,
    isapproved: false,
    is_booking_payment_sms: false,
    is_booking_sms: false,
    is_report_sms: false,
    is_report_whatsapp: false,
    is_report_email: false,
    is_shown_to_pha: false,
    is_shown_to_sales: false,
    is_auto_verify: false,
    is_price_hide: false,
    is_invoice_send: false,
  });
  const [mobileValidation, setMobileValidation] = useState({
    mobileNumber: true,
    centerHeadalterNateNumber: true,
    centerHeadalContactNumber: true,
    altNumber: true,
    finSpocNumber: true,
  });
  const [centerShow, setCentreShow] = useState<boolean>(false);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [CenterId, setCenterid] = React.useState<any>("");
  const [collectionCharge, setCollectionCharge] = useState<any>("");
  const [merCharges, setMerCharges] = useState<any>("");
  const [ecgCharges, setEcgCharges] = useState<any>("");
  const [minOrder, setMinOrder] = useState<any>("");
  const [centerHeadShow, setCentreHeadShow] = useState<boolean>(false);
  const [centerBankShow, setCentreBankShow] = useState<boolean>(false);
  const [sampCollectionDailySummary, setSampCollectionDailySummary] =
    useState<boolean>(false);
  const [reportSentDailySummary, setReportSentDailySummary] =
    useState<boolean>(false);
  const [centerControlingShow, setCentreControllingShow] =
    useState<boolean>(false);
  const [assignLabId, setAssignLabId] = useState<any>(5);
  const [editButton, setEditButton] = useState<any>(false);
  const [searchCentreCodeName, setSearchCentreCodeName] = useState<any>("");
  const [finSpocNumber, setFinSpocNumber] = useState<any>("");
  const [finSpocName, setFinSpocName] = useState<any>("");
  const [finSpocEmail, setFinSpocEmail] = useState<any>("");
  const [invoiceEmails, setInvoiceEmails] = useState<any>([""]);
  const [billingCycle, setBillingCycle] = useState<any>([""]);
  const [termPeriod, setTermPeriod] = useState<any>([""]);
  const gstRegex = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  );
  const panRegex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
  const ifscRegex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
  const updateInvEmail = (val: any, index: any) => {
    let arr = [...invoiceEmails];
    arr[index] = val;
    setInvoiceEmails(arr);
  };

  const addRemv = (add: any, index: any) => {
    if (add) {
      let arr = [...invoiceEmails];
      arr.push("");
      setInvoiceEmails(arr);
    } else {
      let arr = [...invoiceEmails];
      arr.splice(index, 1);
      setInvoiceEmails(arr);
    }
  };
  useEffect(() => {
    dispatch(getCentreCode({ url: "corporate" }));
    dispatch(getIdentityProof({}));
    dispatch(getPinCode({}));
    dispatch(getBankName({}));
    dispatch(getMessageTemplates({}));
    dispatch(getWhatsappTemplates({}));
  }, []);

  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "mob" &&
      mobileNumber &&
      !mobileRegEx.test(mobileNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        mobileNumber: false,
      }));
    }
    if (
      type === "centAlt" &&
      altNumber &&
      !mobileRegEx.test(altNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        altNumber: false,
      }));
    }
    if (
      type === "contAlt" &&
      centerHeadalterNateNumber &&
      !mobileRegEx.test(centerHeadalterNateNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        centerHeadalterNateNumber: false,
      }));
    }
    if (
      type === "contMob" &&
      centerHeadalContactNumber &&
      !mobileRegEx.test(centerHeadalContactNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        centerHeadalContactNumber: false,
      }));
    }
    if (
      type === "finMob" &&
      finSpocNumber &&
      !mobileRegEx.test(finSpocNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        finSpocNumber: false,
      }));
    }
  };

  const salesManagerTextData = [
    {
      value: "Manoj Guleria",
      text: "Manoj Guleria",
    },
    {
      value: "Fuzail Khan",
      text: "Fuzail Khan",
    },
    {
      value: "Irshad Ansari",
      text: "Irshad Ansari",
    },
    {
      value: "Suman Tewary",
      text: "Suman Tewary",
    },
    {
      value: "Ashish Pokhriyal",
      text: "Ashish Pokhriyal",
    },
    {
      value: "Manish Bharadwaj",
      text: "Manish Bharadwaj",
    },
    {
      value: "Jayraj Chauhan",
      text: "Jayraj Chauhan",
    },
    {
      value: "Abhay Singavi",
      text: "Abhay Singavi",
    },
    {
      value: "Sachin Sharma",
      text: "Sachin Sharma",
    },
    {
      value: "Ashish Pokhriyal",
      text: "Ashish Pokhriyal",
    },
    {
      value: "Snehith Goud",
      text: "Snehith Goud",
    },
    {
      value: "P.K.Sharma",
      text: "P.K.Sharma",
    },
    {
      value: "Tushar Oza",
      text: "Tushar Oza",
    },
    {
      value: "Satya Prakash",
      text: "Satya Prakash",
    },
    {
      value: "Anjali Gupta",
      text: "Anjali Gupta",
    },
    {
      value: "Kuldeep Singh",
      text: "Kuldeep Singh",
    },
    {
      value: "Anisha Rautela",
      text: "Anisha Rautela",
    },
    {
      value: "DSR",
      text: "DSR",
    },
  ];

  const CenterTypeData = [
    {
      value: "Doctor Consulting Platform",
      text: "Doctor Consulting Platform",
    },
    {
      value: "Offline Pharmacy Chain/Partner",
      text: "Offline Pharmacy Chain/Partner",
    },
    {
      value: "Third Party Administrator",
      text: "Third Party Administrator",
    },
    {
      value: "Corporate",
      text: "Corporate",
    },
    {
      value: "Corporate Wellness Platform and aggregators",
      text: "Corporate Wellness Platform and aggregators",
    },
    {
      value: "Banks",
      text: "Banks",
    },
    {
      value: "New age Insuretech platform",
      text: "New age Insuretech platform",
    },
    {
      value: "Insurance",
      text: "Insurance",
    },
    {
      value: "Broker",
      text: "Broker",
    },
    {
      value: "RWA",
      text: "RWA",
    },
    {
      value: "Community Association",
      text: "Community Association",
    },
    {
      value: "PSUs",
      text: "PSUs",
    },
    {
      value: "Logistics company",
      text: "Logistics company",
    },
    {
      value: "Govt Panel",
      text: "Govt Panel",
    },
    {
      value: "Disease management platform",
      text: "Disease management platform",
    },
    {
      value: "Pharma",
      text: "Pharma",
    },
    {
      value: "EMR Platforms",
      text: "EMR Platforms",
    },
    {
      value: "Homecare",
      text: "Homecare",
    },
    {
      value: "Wellness platforms",
      text: "Wellness platforms",
    },
    {
      value: "Affinity",
      text: "Affinity",
    },
    {
      value: "Affiliates",
      text: "Affiliates",
    },
    {
      value: "Offline Clinic Chains",
      text: "Offline Clinic Chains",
    },
    {
      value: "Radiology Centres",
      text: "Radiology Centres",
    },
    {
      value: "Surgicare platforms",
      text: "Surgicare platforms",
    },
    {
      value: "PPMC - Aggregator",
      text: "PPMC - Aggregator",
    },
    {
      value: "PPMC - TPA",
      text: "PPMC - TPA",
    },
    {
      value: "Others",
      text: "Others",
    },
    {
      value: "Offline partner",
      text: "Offline partner",
    },
    {
      value: "Diagnostic Center",
      text: "Diagnostic Center",
    },
    {
      value: "DSA",
      text: "DSA",
    },
  ];

  const salutationData = [
    {
      value: "Mr",
      text: "Mr",
    },
    {
      value: "Master",
      text: "Master",
    },
    {
      value: "Miss",
      text: "Miss",
    },
    {
      value: "Smt",
      text: "Smt",
    },
    {
      value: "Dr.",
      text: "Dr.",
    },
  ];

  const fileSelectedHandler = (e: React.ChangeEvent<{ files: any }>) => {
    setFile(e.target.files[0]);
  };
  const fileSelectedHandlerGst = (e: React.ChangeEvent<{ files: any }>) => {
    setFileGst(e.target.files[0]);
  };
  const fileSelectedHandlerAgr = (e: React.ChangeEvent<{ files: any }>) => {
    setAgreementFile(e.target.files[0]);
  };
  useEffect(() => {
    if (centreCode && editButton === false) {
      setCentreCode(centreCode.centre_code);
    }
  }, [centreCode]);

  useEffect(() => {}, []);
  useEffect(() => {
    if (centreCode && editButton === false) {
      setCentreCode(centreCode.centre_code);
    }
  }, [centreCode]);

  const handleSmsControl = (event: any) => {
    setSmsType({ ...smsType, [event.target.name]: event.target.checked });
  };

  const handleSubmitCentre = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", centerName);
    formData.append("display_name", centerName);
    formData.append("legal_name", legalName);
    formData.append("subtype", centerType);
    formData.append("sales_manager_text", salesManagerText);
    if (!editButton) {
      formData.append("center_code", centerCode);
    }
    formData.append("contract_start_date", contractDate);
    formData.append("contract_end_date", expiryDate);
    formData.append("mobile", mobileNumber);
    if (altNumber) {
      formData.append("alternative_phone", altNumber);
    }

    formData.append("email", email);
    if (identityProof) {
      formData.append("identity_proof", identityProof);
    }
    if (bookingLimit) {
      formData.append("max_no_booking", bookingLimit);
    }
    formData.append("identity_proof_file", file);
    if (agreementFile.length !== 0) {
      formData.append("agreement", agreementFile);
    }
    formData.append("address", centerAddress);
    if (pinCodeId) {
      formData.append("location", pinCodeId);
      formData.append("city", cityId);
    }
    formData.append("type", "prepaid");
    formData.append("owner_salutation", designation);
    formData.append("owner_name", ownerName);
    formData.append("center_key", centerKey);
    if (centerHeadalterNateNumber) {
      formData.append("owner_alt_phone", centerHeadalterNateNumber);
    }
    if (centerHeadalContactNumber) {
      formData.append("owner_phone", centerHeadalContactNumber);
    }
    formData.append("owner_nationality", nationality);
    if (adharNumber) {
      formData.append("aadhar_no", adharNumber);
    }
    if (panNumber) {
      formData.append("pan", panNumber);
    }

    if (bankAccountHolder) {
      formData.append("account_holder_name", bankAccountHolder);
    }
    if (ifscCode) {
      formData.append("bank_ifsc", ifscCode);
    }

    if (gstinNumber) {
      formData.append("gstin", gstinNumber);
    }
    if (accountNumber) {
      formData.append("bank_account_no", accountNumber);
    }
    if (bank) {
      formData.append("bank", bank);
    }
    formData.append("is_billing_lock", JSON.stringify(smsType.isbillinglock));
    if (serviceRateCategory !== "") {
      formData.append("multiple_ratelist", String(serviceRateCategory));
    }
    if (reportEmailSentTo) {
      formData.append(
        "report_email_sent_to",
        reportEmailSentTo === "none" ? "not_send" : reportEmailSentTo
      );
    }
    if (centreAccred) {
      formData.append("center_accreditation", centreAccred);
    }

    formData.append("is_mail", JSON.stringify(smsType.mail));
    formData.append("is_sms", JSON.stringify(smsType.sms));
    formData.append("is_screen", JSON.stringify(smsType.screen));
    formData.append(
      "is_booking_payment_sms",
      JSON.stringify(smsType.is_booking_payment_sms)
    );
    formData.append("is_booking_sms", JSON.stringify(smsType.is_booking_sms));
    formData.append("is_invoice_send", JSON.stringify(smsType.is_invoice_send));

    formData.append("is_shown_to_pha", JSON.stringify(smsType.is_shown_to_pha));
    formData.append(
      "is_shown_to_sales",
      JSON.stringify(smsType.is_shown_to_sales)
    );
    formData.append("is_auto_verify", JSON.stringify(smsType.is_auto_verify));
    formData.append("is_price_hide", JSON.stringify(smsType.is_price_hide));

    formData.append("center_type", "corporate");

    formData.append("country", "1");
    if (minOrder && collectionCharge) {
      formData.append("ced_minimum_order_charges", String(minOrder));
      formData.append("ced_collection_charges", String(collectionCharge));
    }
    merCharges && formData.append("ced_mer_charges", String(merCharges));
    ecgCharges && formData.append("ced_ecg_charges", String(ecgCharges));
    formData.append("msgs_send_on", messagesSentThrough);
    let paymnt: any =
      (bookingSms &&
        (bookingSms === "with"
          ? process.env.REACT_APP_ENV === "production"
            ? [9]
            : [44]
          : process.env.REACT_APP_ENV === "production"
          ? [8]
          : [45])) ||
      [];

    formData.append(
      "templates",
      messagesTemplates.concat(whatsappTemplates, paymnt)
    );

    formData.append(
      "can_get_without_header_report",
      String(withoutHeaderReport)
    );
    formData.append(
      "sample_collection_mis",
      String(sampCollectionDailySummary)
    );
    formData.append("report_sent_mis", String(reportSentDailySummary));
    formData.append("invoice_cycle", String(billingCycle));
    formData.append("payment_terms_in_days", String(termPeriod));
    finSpocName && formData.append("finance_spoc_name", String(finSpocName));
    finSpocNumber &&
      formData.append("finance_spoc_mobile_number", String(finSpocNumber));
    formData.append("finance_spoc_email_address", String(finSpocEmail));
    formData.append("other_invoice_emails", String(invoiceEmails));
    formData.append("booking_type", String(sourceType));
    let conEmail: any = {
      client: bookingEmail === "client" || bookingEmail === "both" ? [1] : null,
      customer:
        bookingEmail === "customer" || bookingEmail === "both" ? [1] : null,
    };

    formData.append("email_config", JSON.stringify(conEmail));
    if (fileGst.length !== 0) {
      formData.append("gstin_document", fileGst);
    }
    if (editButton) {
      // await updateCenter({ data: formData, id: centerId });
    } else {
      await dispatch(createNewCenter(formData));
    }

    setEditButton(false);
    setCentreShow(false);
    setCentreHeadShow(false);
    setCentreBankShow(false);
    setCentreControllingShow(false);
  };
  useEffect(() => {
    if (partnerCreated?.id) {
      setScreen("partnerCreated");
    }
  }, [partnerCreated]);

  const handleEditCentre = (data: any) => {
    setDisableEdit(true);
    setEditButton(true);
    setPincodeId(data?.area?.id as Number);
    setPincodeIdDef(data?.area);
    setCityId(data?.area?.city);
    setArea(data?.area?.area as String);
    setPinCode(
      data?.area?.pincode === "null" ||
        data?.area?.pincode === null ||
        data?.area?.pincode === undefined
        ? ""
        : data?.area?.pincode
    );
    setCityName(data?.area?.city_name as String);
    setBarCodeType(data?.barcode_type);
    // setPaymentMode(data?.paymentmode);
    setBankInput(data?.bank?.name);
    setBank(data?.bank?.id);
    // setCategoryName(data?.type);
    setIdentityProof(data?.identity_proof);
    setCentreShow(true);
    setCentreHeadShow(true);
    setCentreBankShow(true);
    setCentreControllingShow(true);
    setCentreName(data?.display_name);
    setLegalName(data?.legal_name);
    setCentreType(data?.subtype);
    setSalesManagerText(data?.sales_manager_text);
    setCentreCode(data?.center_code);
    setContractDate(data?.contract_start_date);
    setExpiryDate(data?.contract_end_date);
    setMobileNumber(data?.mobile);
    setaltNumber(data?.alternative_phone);
    setCentreHeadContactNumber(data?.center_head_phone);
    setCentreHeadAlternateNumber(data?.owner_alt_phone);
    setDesignation(data?.owner_salutation);
    setCentreHeadContactNumber(data?.owner_phone);
    setEmail(data?.email);
    setWithoutHeaderReport(data?.can_get_without_header_report);
    setSampCollectionDailySummary(data?.sample_collection_mis);
    setReportSentDailySummary(data?.report_sent_mis);
    setOwnerName(data?.owner_name);
    setCentreAddress(data?.address);
    setSecurityAmount(data?.security_amount);
    setServiceRateCategoryDef(data.ratelist[0]);
    setServiceRateCategory([data.ratelist[0]?.id]);
    setbankAccountHolder(data?.account_holder_name);
    setGstinNumber(data?.gstin);
    setPanNumber(data?.pan);
    setIfscCode(data?.bank_ifsc);
    setAccountNumber(data?.bank_account_no);
    setCenterId(data?.id);
    setAdharNumber(data?.aadhar_no);
    setAgreementLink(data?.agreement);
    data?.agreement && setIsAgreement(true);
    data?.max_no_booking && setBookingLimit(String(data?.max_no_booking));
    setCenterKey(data?.center_key);
    setMessagesSentThrough(data?.msgs_send_on || "");
    setMessagesTemplates(
      data?.message_template?.length > 0
        ? data?.message_template.map((val: any) => val.id)
        : []
    );
    setWhatsappTemplates(data?.email_templates || []);
    setMessagesTemplatesDef(data?.message_template);
    // setReportWhatsappSentTo(data?.report_whatsapp_sent_to);
    setReportEmailSentTo(data?.report_email_sent_to);
    const smType = {
      mail: data.is_mail,
      sms: data.is_sms,
      screen: data.is_screen,
      isbillinglock: data.is_billing_lock,
      isapproved: data.is_active,
      is_booking_payment_sms: data.is_booking_payment_sms,
      is_booking_sms: data.is_booking_sms,
      is_report_sms: data.is_report_sms,
      is_report_whatsapp: data.is_report_whatsapp,
      is_report_email: data.is_report_email,
      is_shown_to_pha: data.is_shown_to_pha,
      is_shown_to_sales: data.is_shown_to_sales,
      is_auto_verify: data.is_auto_verify,
      is_price_hide: data.is_price_hide,
      is_invoice_send: data.is_invoice_send,
    };

    setSmsType(smType);
  };
  useEffect(() => {
    // getRateListFilter(tag_ProccessingLab, "");
  }, [tag_ProccessingLab]);

  useEffect(() => {
    if (
      addressStatus === "reset" &&
      pin_code?.results &&
      pin_code?.results.length > 0
    ) {
      setCityId(pin_code?.results[0].cityid);
      setPinCode(pin_code?.results[0].pincode);
      setPincodeId(pin_code?.results[0].id);
      setArea(pin_code?.results[0].area);
      setCityName(pin_code?.results[0].city);
    }
  }, [pin_code, addressStatus]);
  useEffect(() => {
    if (sourceType) {
      setCollectionCharge("");
      setMinOrder("");
      setMerCharges("");
      setEcgCharges("");
    }
  }, [sourceType]);

  return (
    <form onSubmit={handleSubmitCentre}>
      <Box sx={{ flexGrow: 1 }}>
        {/* PARTNER CREATION */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className={"dialogContent"}>
            <Grid item xs={10}>
              <Typography component="p">PARTNER CREATION</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expand ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpand(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpand(true)}
                />
              )}
            </Grid>
            {expand ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="title"
                    type="text"
                    label="Partner Code"
                    value={centerCode}
                    required
                    disabled
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="center_name"
                    type="text"
                    label="Partner Name"
                    value={centerName}
                    required
                    inputProps={{ maxLength: 25, minLength: 3 }}
                    variant="outlined"
                    onChange={(e) =>
                      String(e.target.value).length < 26
                        ? setCentreName(
                            String(e.target.value)
                              .replace(/[^a-zA-Z ]/g, "")
                              .trimStart()
                          )
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="center_name"
                    type="text"
                    label="Legal Partner Name"
                    value={legalName}
                    required
                    inputProps={{ maxLength: 25, minLength: 3 }}
                    variant="outlined"
                    onChange={(e) =>
                      String(e.target.value).length < 26
                        ? setLegalName(
                            String(e.target.value)
                              .replace(/[^a-zA-Z ]/g, "")
                              .trimStart()
                          )
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="center_name"
                    type="text"
                    label="Partner Key"
                    value={centerKey}
                    required
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    onChange={(e) => setCenterKey(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="centre_type"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCentreType(obj.value);
                      }
                    }}
                    defaultValue={
                      CenterTypeData &&
                      CenterTypeData.find((code: any) => {
                        return code.value === centerType;
                      })
                    }
                    options={CenterTypeData}
                    blurOnSelect
                    getOptionLabel={(option: any) => `${option?.text}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub Partner Type"
                        variant="outlined"
                        style={{ width: "100%" }}
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="contract_date"
                    type="date"
                    value={contractDate}
                    size="small"
                    label="Contract Date"
                    variant="outlined"
                    required
                    inputProps={{
                      min: editButton
                        ? moment(contractDate).format("YYYY-MM-DD")
                        : moment().format("YYYY-MM-DD"),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                    onChange={(e) => setContractDate(e.target.value as string)}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="expiry_date"
                    type="date"
                    disabled={contractDate === ""}
                    value={expiryDate}
                    size="small"
                    label="Expiry Date"
                    inputProps={{
                      min: moment(contractDate)
                        .add(1, "days")
                        .format("YYYY-MM-DD"),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      moment(expiryDate).isAfter(contractDate)
                        ? ""
                        : "Expiry Date Should Greater Than Contract Date"
                    }
                    variant="outlined"
                    onChange={(e) => setExpiryDate(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="mobile_number"
                    type="number"
                    label="Mobile Number"
                    value={mobileNumber}
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) => setMobileNumber(e.target.value as String)}
                    required
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onBlur={() => verifyMobile("mob")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        mobileNumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.mobileNumber &&
                      "Incorrect Mobile Number"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="alt_number"
                    type="number"
                    label="Alternate Number"
                    value={altNumber}
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) => setaltNumber(e.target.value as String)}
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onBlur={() => verifyMobile("centAlt")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        altNumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.altNumber &&
                      "Incorrect Alternate Number"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    type="email"
                    value={email}
                    size="small"
                    label="Email "
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    id="identityproof"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setIdentityProof(obj.id);
                      }
                    }}
                    defaultValue={
                      identityProofData &&
                      identityProofData.find((code: any) => {
                        return code.id === Number(identityProof);
                      })
                    }
                    options={identityProofData || []}
                    getOptionLabel={(option: any) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="KYC Document"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="file"
                    name="file"
                    id="file"
                    size="small"
                    fullWidth
                    label="Upload KYC Document"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FileUploadIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e: any) => fileSelectedHandler(e)}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <Autocomplete
                    id="sales-manager-text"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setSalesManagerText(obj.value);
                      }
                    }}
                    defaultValue={
                      salesManagerTextData &&
                      salesManagerTextData.find((code: any) => {
                        return code.value === salesManagerText;
                      })
                    }
                    options={salesManagerTextData}
                    freeSolo
                    blurOnSelect
                    disabled={editButton === true ? true : false}
                    getOptionLabel={(option: any) => `${option?.text}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sales Manager Text"
                        variant="outlined"
                        style={{ width: "100%" }}
                        size="small"
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    name="Adhar_Number"
                    type="number"
                    value={adharNumber}
                    size="small"
                    label="Aadhaar Number "
                    variant="outlined"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 12);
                    }}
                    helperText={
                      adharNumber?.length < 12 || adharNumber === null
                        ? "Enter a valid 12 Digit Aadhaar No."
                        : ""
                    }
                    onChange={(e) =>
                      e.target.value && Number(e.target.value) < 2
                        ? ""
                        : setAdharNumber(e.target.value as string)
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="partnerAddress"
                    type="text"
                    value={centerAddress}
                    size="small"
                    label="Partner Address"
                    variant="outlined"
                    inputProps={{ maxLength: 150 }}
                    onChange={(e) => setCentreAddress(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="pincode"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setPincodeId(obj?.id as Number);
                        setArea(obj.area as String);
                        setCityName(obj.city as String);
                        setCityId(obj.cityid);
                      }
                    }}
                    defaultValue={
                      pinCodeIdDef || {
                        id: "",
                        area: "",
                        city: "",
                        cityid: "",
                        pincode: "",
                      }
                    }
                    options={pin_code?.results || []}
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      option?.pincode !== ""
                        ? `${option?.pincode + "," + option?.area}`
                        : ""
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {}, 1000);
                      dispatch(getPinCode({ url: newInputValue }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Pin Code"
                        required
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="area"
                    type="text"
                    value={area}
                    size="small"
                    label="Area "
                    variant="outlined"
                    disabled
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="city"
                    type="text"
                    value={cityName}
                    size="small"
                    label="City Name "
                    variant="outlined"
                    disabled
                    style={{ width: "100%" }}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* PARTNER HEAD DETAILS */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">PARTNER HEAD DETAILS</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expandGeneral ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandGeneral(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandGeneral(true)}
                />
              )}
            </Grid>
            {expandGeneral ? (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="salutation"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setDesignation(obj.value);
                      }
                    }}
                    defaultValue={
                      salutationData &&
                      salutationData.find((code: any) => {
                        return code.value === designation;
                      })
                    }
                    options={salutationData}
                    blurOnSelect
                    getOptionLabel={(option: any) => option?.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Salutation"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Ownername"
                    type="text"
                    value={ownerName}
                    size="small"
                    label="Owner Name"
                    variant="outlined"
                    inputProps={{ maxLength: 25, minLength: 3 }}
                    onChange={(e) =>
                      String(e.target.value).length < 26
                        ? setOwnerName(
                            String(e.target.value)
                              .replace(/[^a-zA-Z ]/g, "")
                              .trimStart()
                          )
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="contact"
                    type="number"
                    label="Partner Head Mobile Number"
                    value={centerHeadalContactNumber}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCentreHeadContactNumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("contMob")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        centerHeadalContactNumber: true,
                      }));
                    }}
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    helperText={
                      !mobileValidation.centerHeadalContactNumber &&
                      "Incorrect Mobile Number"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="contact"
                    type="number"
                    label="Partner Head Alternate Number"
                    value={centerHeadalterNateNumber}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCentreHeadAlternateNumber(e.target.value as String)
                    }
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onBlur={() => verifyMobile("contAlt")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        centerHeadalterNateNumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.centerHeadalterNateNumber &&
                      "Incorrect Alternate Number"
                    }
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* PARTNER BANK DETAILS */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">PARTNER BANK DETAILS</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expandAddr ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandAddr(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandAddr(true)}
                />
              )}
            </Grid>
            {expandAddr ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="acountholder"
                    type="text"
                    value={bankAccountHolder}
                    size="small"
                    inputProps={{ maxLength: 25, minLength: 3 }}
                    label="Account Holder Name"
                    variant="outlined"
                    onChange={(e) =>
                      setbankAccountHolder(  String(e.target.value)
                      .replace(/[^a-zA-Z ]/g, "")
                      .trimStart())
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="bank"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setBank(obj.id);
                      }
                    }}
                    defaultValue={
                      bankName?.count > 0 &&
                      bankName?.results.find((code: any) => {
                        return code.id === bank;
                      })
                    }
                    blurOnSelect
                    options={bankName?.results || []}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option?.name}
                    onInputChange={(event, newInputValue) => {
                      dispatch(getBankName({ url: `?name=${newInputValue}` }));
                      setBankInput(newInputValue);
                    }}
                    inputValue={bankInput}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Bank Name"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="GSTINNumber"
                    type="text"
                    value={gstinNumber}
                    size="small"
                    label="GSTIN"
                    variant="outlined"
                    helperText={
                      gstinNumber.length > 0 && !gstRegex.test(gstinNumber)
                        ? "Wrong GSTIN number! (eg. 22AAAAA0000A1Z5)"
                        : ""
                    }
                    onChange={(e) =>
                      e.target.value.length < 16
                        ? setGstinNumber(e.target.value.toUpperCase())
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="file"
                    name="file"
                    id="file"
                    size="small"
                    fullWidth
                    label="Upload GSTIN Document"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{accept:"application/pdf"}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FileUploadIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e: any) => fileSelectedHandlerGst(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="panNumber"
                    type="text"
                    value={panNumber}
                    size="small"
                    label="PAN"
                    variant="outlined"
                    helperText={
                      panNumber.length > 0 && !panRegex.test(panNumber)
                        ? "Wrong PAN number! (eg. ABCTY1234D)"
                        : ""
                    }
                    onChange={(e) =>
                      e.target.value.length < 11
                        ? setPanNumber(e.target.value.toUpperCase())
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="IFSC"
                    type="text"
                    value={ifscCode}
                    size="small"
                    label="IFSC Code"
                    variant="outlined"
                    helperText={
                      ifscCode.length > 0 && !ifscRegex.test(ifscCode)
                        ? "Wrong IFSC code! (eg. SBIN0005943)"
                        : ""
                    }
                    onChange={(e) =>
                      e.target.value.length < 12
                        ? setIfscCode(e.target.value.toUpperCase())
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="accountNumber"
                    value={accountNumber}
                    size="small"
                    label="Account Number"
                    variant="outlined"
                    onChange={(e) =>
                      String(e.target.value).length < 18
                        ? setAccountNumber(
                            e.target.value.replace(/[^0-9]/g, "")
                          )
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* CONFIGURATION DETAILS */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">CONFIGURATION DETAILS</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expandPkg ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandPkg(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandPkg(true)}
                />
              )}
            </Grid>
            {expandPkg ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    select
                    name="sample_collected"
                    variant="outlined"
                    value={sourceType}
                    required
                    fullWidth
                    label="Booking Type"
                    size="small"
                    onChange={(e) => setSourceType(e.target.value)}
                  >
                    <MenuItem value={"CAMP"}>CAMP</MenuItem>
                    <MenuItem value={"Drop-Off"}>Drop-Off</MenuItem>
                    <MenuItem value={"ECG At Home"}>ECG At Home</MenuItem>
                    <MenuItem value={"Home Collection"}>
                      Home Collection
                    </MenuItem>
                    <MenuItem value={"Walkins Lab"}>Walkins Lab</MenuItem>
                    <MenuItem value={"Walkins CC"}>Walkins CC</MenuItem>
                    <MenuItem value={"PPMC"}>PPMC</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={bookingLimit}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setBookingLimit(e.target.value)}
                    fullWidth
                    select
                    label="Booking Limit"
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                  </TextField>
                </Grid>
                {sourceType === "Home Collection" || sourceType === "PPMC" ? (
                  <Grid item xs={12}>
                    <TextField
                      name="accountNumber"
                      value={collectionCharge}
                      size="small"
                      label="Collection charges amount"
                      variant="outlined"
                      onChange={(e) => {
                        setCollectionCharge(
                          e.target.value.replace(/[^0-9]/g, "")
                        );
                        (collectionCharge === "" || collectionCharge === "0") &&
                          setMinOrder("");
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {collectionCharge && collectionCharge !== "0" ? (
                  <Grid item xs={12}>
                    <TextField
                      name="accountNumber"
                      value={minOrder}
                      size="small"
                      label="Minimum order value"
                      variant="outlined"
                      onChange={(e) =>
                        setMinOrder(e.target.value.replace(/[^0-9]/g, ""))
                      }
                      style={{ width: "100%" }}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {sourceType === "PPMC" ? (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        name="accountNumber"
                        value={merCharges}
                        size="small"
                        label="MER charges amount"
                        variant="outlined"
                        onChange={(e) =>
                          setMerCharges(e.target.value.replace(/[^0-9]/g, ""))
                        }
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="accountNumber"
                        value={ecgCharges}
                        size="small"
                        label="ECG charges amount"
                        variant="outlined"
                        onChange={(e) =>
                          setEcgCharges(e.target.value.replace(/[^0-9]/g, ""))
                        }
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                <Grid item xs={12} style={{ color: "#747474" }}>
                  Finance
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={billingCycle}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setBillingCycle(e.target.value)}
                    fullWidth
                    select
                    label="Billing Cycle"
                  >
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="15">Bi-Weekly</MenuItem>
                    <MenuItem value="7">Weekly</MenuItem>
                    <MenuItem value="camp_completion">CAMP Completion</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={termPeriod}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setTermPeriod(e.target.value)}
                    fullWidth
                    select
                    label="Term Period(in Days)"
                  >
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="60">60</MenuItem>
                    <MenuItem value="90">90</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Ownername"
                    type="text"
                    value={finSpocName}
                    size="small"
                    label="Finance Spoc Name"
                    variant="outlined"
                    onChange={(e) => setFinSpocName(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="contact"
                    type="number"
                    label="Finance Spoc Mobile Number"
                    value={finSpocNumber}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) => setFinSpocNumber(e.target.value as String)}
                    onBlur={() => verifyMobile("finMob")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        finSpocNumber: true,
                      }));
                    }}
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    helperText={
                      !mobileValidation.finSpocNumber &&
                      "Incorrect Mobile Number"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    type="email"
                    value={finSpocEmail}
                    size="small"
                    label="Finance Spoc Email Address"
                    variant="outlined"
                    onChange={(e) => setFinSpocEmail(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                {invoiceEmails.length > 0 &&
                  invoiceEmails.map((val: any, index: any) => {
                    return (
                      <Grid item xs={12} textAlign={"right"}>
                        {index === invoiceEmails.length - 1 ? (
                          <IconButton>
                            <AddCircleOutlineIcon
                              onClick={() => addRemv(true, index)}
                            />{" "}
                          </IconButton>
                        ) : (
                          ""
                        )}

                        {invoiceEmails.length !== 1 ? (
                          <IconButton>
                            <RemoveCircleOutlineIcon
                              onClick={() => addRemv(false, index)}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        <TextField
                          name="email"
                          type="email"
                          value={val}
                          size="small"
                          label="Other Invoice Emails"
                          variant="outlined"
                          onChange={(e) =>
                            updateInvEmail(e.target.value as string, index)
                          }
                          style={{ width: "80%" }}
                          required
                        />
                      </Grid>
                    );
                  })}

                <Grid item xs={12}>
                  <TextField
                    type="file"
                    name="file"
                    id="file"
                    size="small"
                    fullWidth
                    label="Upload Agreement"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FileUploadIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e: any) => fileSelectedHandlerAgr(e)}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* COMMUNICATION DETAILS */}
        <Card sx={{ borderRadius: "16px", m: 1, padding: "10px 0px" }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">COMMUNICATION DETAILS</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              {expandRep ? (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandRep(false)}
                />
              ) : (
                <ExpandMoreIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setExpandRep(true)}
                />
              )}
            </Grid>
            {expandRep ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    select
                    value={reportEmailSentTo}
                    variant="outlined"
                    onChange={(e) => setReportEmailSentTo(e.target.value)}
                    fullWidth
                    label="Report Email Sent to"
                    size="small"
                  >
                    <MenuItem value="client">Client</MenuItem>
                    <MenuItem value="customer">Customer</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                    {/* <MenuItem value="not_send">Not Send</MenuItem> */}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    size="small"
                    value={bookingEmail}
                    variant="outlined"
                    onChange={(e) => setBookingEmail(e.target.value)}
                    fullWidth
                    label="Booking Confirmation Email Sent to"
                  >
                    <MenuItem value="client">Client</MenuItem>
                    <MenuItem value="customer">Customer</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    size="small"
                    value={bookingSms}
                    variant="outlined"
                    onChange={(e) => setBookingSms(e.target.value)}
                    fullWidth
                    label="Booking Confirmation SMS"
                  >
                    <MenuItem value="with">With Payment Link</MenuItem>
                    <MenuItem value="without">Without Payment Link</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj.id);
                        });
                        setMessagesTemplates(packs);
                      }
                    }}
                    multiple
                    limitTags={1}
                    defaultValue={messagesTemplatesDef}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        dispatch(
                          getMessageTemplates(`&search=${newInputValue}`)
                        );
                      }, 1000);
                    }}
                    options={messageTemplate || []}
                    getOptionLabel={(option: any) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="SMS Templates"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj.id);
                        });
                        setWhatsappTemplates(packs);
                      }
                    }}
                    defaultValue={whatsappTemplatesDef}
                    multiple
                    limitTags={1}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        dispatch(
                          getWhatsappTemplates(`&search=${newInputValue}`)
                        );
                      }, 1000);
                    }}
                    options={whatsappTemplate || []}
                    getOptionLabel={(option: any) =>
                      `${option?.name ? option?.name : option?.template_name}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Whatsapp Templates"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Card>
        {/* button */}
        {partnerData?.id ? (
          <div style={{ margin: "0 15px 15px" }}>
            <StyledButtonLight onClick={() => setScreen("partnerView")}>
              BACK
            </StyledButtonLight>
          </div>
        ) : (
          <>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButton
                type="submit"
                disabled={
                  (gstinNumber.length > 0 && !gstRegex.test(gstinNumber)) ||
                  (panNumber.length > 0 && !panRegex.test(panNumber)) ||
                  (ifscCode.length > 0 && !ifscRegex.test(ifscCode))
                }
              >
                SUBMIT
              </StyledButton>
            </div>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButtonLight
                onClick={() => {
                  setPartnerData({});
                  setScreen("partnerView");
                }}
              >
                BACK
              </StyledButtonLight>
            </div>
          </>
        )}
      </Box>
    </form>
  );
};

export default SalesForm;
