import React, { useEffect, useState } from "react";
import { Grid, Card, Box, Typography } from "@mui/material";
import Header from "../components/Header";
import confirm from "../images/confirm.png";
import { resetState } from "../services/actionSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../storage/store";
interface Props {}

const Confirmation: React.FC<Props> = ({}) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(resetState())
  }, [])
  
  return (
    <Grid className="content" style={{padding:"20px 30px"}}>

      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12} textAlign="center">
              <img src={confirm} />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography component="h5" variant="h5">
                Partner Created Successful
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography component="p">
                Pending Partner Approval Email has been sent to the approving
                authority
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Grid>
  );
};

export default Confirmation;


