import {
  createSlice,
  createAsyncThunk,
  AsyncThunkAction,
} from "@reduxjs/toolkit";
import Prefix from "../config/ApiPrefix";
import AXIOS from "../config/Axios";
import AXIOSV3 from "../config/AxiosV3";
import SecureStorage from "../config/SecureStorage";


export const getPackagesData = createAsyncThunk(
  "getPackagesData",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/external/v2/ac-center-package-data/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);


export const getTestStatus = createAsyncThunk(
  "getTestStatus",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/external/v1/booking-phlebo-code-verification/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getCentreByKey = createAsyncThunk(
  "getCentreByKey",
  async (key: string) => {
    try {
      const { data } = await AXIOSV3.get(
        `api/external/v1/activity-configuration/${key ? key : ""}/`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);

export const postMobileForOtp = createAsyncThunk(
  "postMobileForOtp",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/notification/send-whatsapp-otp/?otp_type=sms`,
        body
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);


// LogCapture
export const logCapture = createAsyncThunk(
  "logCapture",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/partner/frontendlogs/`,
        body
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);

// LogCapture


export const postVerifyOtp = createAsyncThunk(
  "postVerifyOtp",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/notification/verify-whatsapp-otp/`,
        body
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const checkGeoFenchMapMyIndia = createAsyncThunk(
  "checkGeoFenchMapMyIndia",
  async (val: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia_2/?eloc=${val.eloc}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message:
            "The entered location is non-serviceable. Our backend team will connect with you." ||
            "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);

export const getPartnerKey = createAsyncThunk(
  "getPartnerKey",
  async (cid: any) => {
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/partner/3p-key/${cid}/`);
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getLocationrRevGeoCode = createAsyncThunk(
  "getLocationrRevGeoCode",
  async (val: any) => {
    try {
      const { data } = await AXIOS.get(
        `https://apis.mapmyindia.com/advancedmaps/v1/${val?.token}/rev_geocode?&lng=${val?.lng}&lat=${val?.lat}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const createBooking = createAsyncThunk(
  "createBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v2/center-create-booking/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const uploadPrescription = createAsyncThunk(
  "uploadPrescription",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v1/prescription-upload-lead/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getValidLocation = createAsyncThunk(
  "getValidLocation",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/center/v2/is-location-serviceable/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message:
            "The entered location is non-serviceable. Our backend team will connect with you." ||
            "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getZone = createAsyncThunk("getZone", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api1}/phlebo/v2/get-zone/${obj?.url ? obj?.url : ""}`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message:
          "The entered location is non-serviceable. Our backend team will connect with you." ||
          "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getCouponCalc = createAsyncThunk(
  "getCouponCalc",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/get_prices/${obj?.url ? obj?.url : ""}`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getCoupon = createAsyncThunk("getCoupon", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/coupon/package-coupon/${obj?.url ? obj?.url : ""}`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getBooking = createAsyncThunk("getBooking", async (obj: any) => {
  try {
    const { data } = await AXIOS.post(
      `/api/external/v1/camp-user-already-in-their/`,
      obj.data,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const postTestStatus = createAsyncThunk(
  "postTestStatus",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `api/external/v1/booking-phlebo-code-verification/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      if(body.notOpen===false){
        window.open(
          window.location.href +
            `&tid=${body?.campBooking?.a_booking_id}&hid=${body?.campBooking?.booking_extra_detail_id}&name=${body?.customerName}`,
          "_self"
        );
      }
   
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const resetState = createAsyncThunk("resetState", async () => {
  try {
    return null;
  } catch (err: any) {
    return null;
  }
});
export const createCampBooking = createAsyncThunk(
  "createCampBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `api/external/v1/camp-add-member/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateTestStatus = createAsyncThunk(
  "updateTestStatus",
  async (body: any) => {
    try {
      const { data } = await AXIOS.put(
        `api/external/v1/booking-phlebo-code-verification/${body.id}/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const submitReview = createAsyncThunk(
  "submitReview",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/booking-feedback-create/?key=${body.key}`,
        body.data
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateHealthVital = createAsyncThunk(
  "updateHealthVital",
  async (body: any) => {
    try {
      const { data } = await AXIOS.put(
        `api/external/v1/camp-booking-extra-detail/${body?.id}/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getHealthVital = createAsyncThunk(
  "getHealthVital",
  async (body: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/external/v1/camp-booking-extra-detail/${body.url ? body.url : ""}`,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPrevBooking = createAsyncThunk(
  "getPrevBooking",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/center-get-booking/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getEmailOtp = createAsyncThunk(
  "getEmailOtp",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/send-custom-iframe-otp/${key ? key : ""}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const smsCampCompleted = createAsyncThunk(
  "smsCampCompleted",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v1/sms-camp-test-completed/?booking=${
          obj.id ? obj.id : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const postOtpForEmail = createAsyncThunk(
  "postOtpForEmail",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/validate-iframe-otp/${key ? key : ""}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getEmpDetails = createAsyncThunk(
  "getEmpDetails",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/validate-iframe-token/`,
        {
          headers: { key: key },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const removeEmpToken = createAsyncThunk(
  "removeEmpToken",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/set-is-used/`,
        {
          headers: { key: key },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
///////sales start/////////
//login
export const postLogin = createAsyncThunk("postLogin", async (body: any) => {
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/users/login/`, {
      user: body,
    });
    const token = data.user.token;
    SecureStorage.setItem("token", token);
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.error).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    SecureStorage.removeItem("token");

    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getOTP = createAsyncThunk("getOTP", async (body: any) => {
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/notification/send_otp/`,
      body
    );
    const newData = {
      get_otp: data,
      snack: {
        message: "OTP has been sent successfully to your mobile number!",
        status: true,
        type: "success",
      },
    };
    return newData;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.error).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    SecureStorage.removeItem("token");

    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const resetPasswordWithOTP = createAsyncThunk(
  "resetPasswordWithOTP",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/auth/resetpass/`, body);
      const newData = {
        resetPasswordWithOTP: data,
        snack: {
          message: "Password Updated",
          status: true,
          type: "success",
        },
      };
      return newData;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      SecureStorage.removeItem("token");

      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
//login end

export const getCities = createAsyncThunk("getCities", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/cities/${obj?.url ? `?q=${obj?.url}` : ""}`,
      SecureStorage.getItem("tokenNew")
        ? {
            headers: { Authorization: `${SecureStorage.getItem("tokenNew")}` },
          }
        : {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getCamp = createAsyncThunk("getCamp", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `api/external/v1/activity-configuration/${
        obj?.url ? `?${obj?.url}` : ""
      }`,
      SecureStorage.getItem("tokenNew")
      ? {
        }
      : {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")}`,
          },
        }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getCampCode = createAsyncThunk("getCampCode", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `api/external/v1/camp-code-generator/${obj?.url ? `?${obj?.url}` : ""}`,
      SecureStorage.getItem("tokenNew")
      ? {
        headers: { Authorization: `${SecureStorage.getItem("tokenNew")}` },
      }
      : {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")}`,
          },
        }
    );
    return data;
  } catch (err: any) {
    let title = "";

    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const createCamp = createAsyncThunk("createCamp", async (body: any) => {
  try {
    const { data } = await AXIOS.post(
      `api/external/v1/activity-configuration/`,
      body,
      SecureStorage.getItem("tokenNew")
      ? {
        headers: { Authorization: `${SecureStorage.getItem("tokenNew")}` },
      }
      : {
          headers: {
            Authorization: `Token ${SecureStorage.getItem("token")}`,
          },
        }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getCenterInfo = createAsyncThunk(
  "getCenterInfo",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/center/data/${obj?.url ? `?${obj?.url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getMapMyIndiaAccessToken = createAsyncThunk(
  "getMapMyIndiaAccessToken",
  async () => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/mapmyindia-auth-token/`,
        SecureStorage.getItem("tokenNew")
        ? {
            headers: { Authorization: `${SecureStorage.getItem("tokenNew")}` },
          }
        : {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPackageData = createAsyncThunk(
  "getPackageData",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/cc-package/${obj.url ? obj.url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const createNewCenter = createAsyncThunk(
  "createNewCenter",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/center/corp-create/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateCenter = createAsyncThunk(
  "updateCenter",
  async (body: any) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/center/update/${body.id}`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getCentreCode = createAsyncThunk(
  "getCentreCode",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/center/get_centre_code/${
          obj?.url ? `?center_type=${obj.url}` : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getIdentityProof = createAsyncThunk(
  "getIdentityProof",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/core/identity/${obj.url ? obj.url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPinCode = createAsyncThunk("getPinCode", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/pincode/${obj?.url ? `?code=${obj.url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getBankName = createAsyncThunk("getBankName", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/bank/${obj?.url ? obj.url : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});

export const getMessageTemplates = createAsyncThunk(
  "getMessageTemplates",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/partner/message-templates/?send_on=sms${
          obj.url ? obj.url : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getWhatsappTemplates = createAsyncThunk(
  "getWhatsappTemplates",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/partner/message-templates/?send_on=whatsapp${
          obj.url ? obj.url : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPartnerData = createAsyncThunk(
  "getPartnerData",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/snowflake/default/${obj.url ? obj.url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getSlots = createAsyncThunk("getSlots", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `api/v1/ppmc/get-time-slot-c-list/${
        obj?.url ? obj?.url : ""
      }`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const sendConfirmBooking = createAsyncThunk(
  "sendConfirmBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `api/external/v2/center-confirm-booking/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateConfirmBooking = createAsyncThunk(
  "updateConfirmBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.put(
        `api/v1/booking/update/${body.booking_id}/`,
       { "is_confirmed": true},
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getUserData = createAsyncThunk("getUserData", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(`api/v1/auth/shortprofile/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getParams = createAsyncThunk(
  "getParams",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `api/external/v2/package-parameter-data/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
///////sales end/////////
//reducers
const initialState = {
  data: {},
  loading: true,
  packageParams: [],
  mobileForOtp: [],
  centreByKey: [],
  verifyOtp: [],
  geoFencesMapMyIndia: {},
  partnerKey: {},
  testStatus: [],
  revGeoCode: [],
  bookingCreated: {},
  snackbarData: {},
  uploadPrescriptionData: {},
  validLocation: {},
  bookingData: {},
  prevBookingData: {},
  bookingUpdated: {},
  healthVital: {},
  zone: {},
  couponCalc: {},
  coupon: {},
  campBooking: {},
  emailOtp: {},
  emailOtpVerified: {},
  empDetails: {},
  updateTestStatusData: {},
  cities: [],
  campList: {},
  campCode: {},
  centerInfo: {},
  mapmyindiaAccessToken: {},
  packageList: {},
  otpResp: {},
  centreCode: {},
  identityProofData: [],
  pin_code: [],
  bankName: [],
  messageTemplate: [],
  whatsappTemplate: [],
  partnerCreated: {},
  partnerData: [],
  userData: {},
  postTestStatusData: {},
  campCreated: {},
  params:[],
  slots:[],
  confirmBooking:{}
};
const webAppSlice = createSlice({
  name: "webAppSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordWithOTP.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(resetPasswordWithOTP.fulfilled, (state, action) => {
        state.snackbarData = action.payload.snack;

        state.otpResp = action.payload;

        state.loading = false;
      })
      .addCase(getOTP.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOTP.fulfilled, (state, action) => {
        state.snackbarData = action.payload.snack;

        state.otpResp = action.payload;

        state.loading = false;
      })

      .addCase(getPackageData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPackageData.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.packageList = action.payload;
        }
        state.loading = false;
      })

      .addCase(getMapMyIndiaAccessToken.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMapMyIndiaAccessToken.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.mapmyindiaAccessToken = action.payload;
        }
        state.loading = false;
      })

      .addCase(getCenterInfo.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCenterInfo.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.centerInfo = action.payload;
        }
        state.loading = false;
      })

      .addCase(getCampCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCampCode.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.campCode = action.payload;
        }
        state.loading = false;
      })

      .addCase(getCamp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCamp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.campList = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.cities = action.payload;
        }
        state.loading = false;
      })
      .addCase(getPackagesData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPackagesData.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.packageParams = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCoupon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCoupon.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.coupon = action.payload;
        }
        state.loading = false;
      })
      .addCase(postMobileForOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.mobileForOtp = action.payload;
        }
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        }
      })
      .addCase(getCentreByKey.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCentreByKey.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.centreByKey = action.payload;
        }
        state.loading = false;
      })
      .addCase(postVerifyOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.verifyOtp = action.payload;
        }
      })
      .addCase(checkGeoFenchMapMyIndia.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.geoFencesMapMyIndia = { status: false };
        } else {
          state.geoFencesMapMyIndia = action.payload;
        }
      })

      .addCase(getPartnerKey.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.partnerKey = action.payload;
        }
      })
      .addCase(getTestStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTestStatus.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.testStatus = action.payload;
        }
        state.loading = false;
      })
      .addCase(getLocationrRevGeoCode.fulfilled, (state, action) => {
        state.revGeoCode = action.payload;
      })
      .addCase(createBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCampBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCampBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.campBooking = action.payload;
        }
        state.loading = false;
      })
      .addCase(createCamp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCamp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.campCreated = action.payload;
        }
        state.loading = false;
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingCreated = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCouponCalc.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCouponCalc.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.couponCalc = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateTestStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateTestStatus.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.updateTestStatusData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getValidLocation.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.validLocation = { valid: false };
        } else {
          state.validLocation = { valid: true };
        }
        state.loading = false;
      })
      .addCase(getBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.bookingData = {};
        } else {
          state.bookingData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getPrevBooking.fulfilled, (state, action) => {
        state.prevBookingData = action.payload;
      })
      .addCase(postTestStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postTestStatus.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.postTestStatusData = { posted: false };
        } else {
          state.postTestStatusData = { posted: true };
        }
        state.loading = false;
      })
      .addCase(resetState.fulfilled, (state, action) => {
        state.bookingUpdated = {};
        state.loading = false;
      })
      .addCase(getZone.fulfilled, (state, action) => {
        state.zone = action.payload;
      })
      .addCase(updateHealthVital.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateHealthVital.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        }
        state.loading = false;
      })
      .addCase(getHealthVital.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHealthVital.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.healthVital = action.payload;
        }
        state.loading = false;
      })
      .addCase(getEmailOtp.pending, (state, action) => {
        state.emailOtp = true;
      })
      .addCase(getEmailOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.emailOtp = action.payload;
        }
        state.loading = false;
      })
      .addCase(postOtpForEmail.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.emailOtpVerified = action.payload.snack;
        } else {
          state.emailOtpVerified = action.payload;
        }
      })
      .addCase(getEmpDetails.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.empDetails = action.payload.snack;
        } else {
          state.empDetails = action.payload;
        }
      })
      .addCase(getCentreCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCentreCode.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.centreCode = action.payload;
        }
        state.loading = false;
      })
      .addCase(getIdentityProof.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getIdentityProof.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.identityProofData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getPinCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPinCode.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.pin_code = action.payload;
        }
        state.loading = false;
      })
      .addCase(getBankName.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBankName.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bankName = action.payload;
        }
        state.loading = false;
      })
      .addCase(getMessageTemplates.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMessageTemplates.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.messageTemplate = action.payload;
        }
        state.loading = false;
      })
      .addCase(getWhatsappTemplates.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getWhatsappTemplates.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.whatsappTemplate = action.payload;
        }
        state.loading = false;
      })
      .addCase(createNewCenter.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createNewCenter.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.partnerCreated = action.payload;
        }
        state.loading = false;
      })
      .addCase(getPartnerData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPartnerData.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.partnerData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getUserData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.userData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getParams.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getParams.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.params = action.payload;
        }
        state.loading = false;
      })
      .addCase(getSlots.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSlots.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.slots = action.payload;
        }
        state.loading = false;
      })
      .addCase(sendConfirmBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(sendConfirmBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.empDetails = action.payload.snack;
        } else {
          state.confirmBooking = {payment:true};
        }
        state.loading = false;
      })
      ;
  },
});

export default webAppSlice.reducer;
