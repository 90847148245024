import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import "./index.css";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import logo from "../../images/logo1.png";
import SecureStorage from "../../config/SecureStorage";
import { Button } from "@mui/material";
const useStyles = makeStyles({
  HeaderStyle: {
    background: "#FFFFFF",
    top: 0,
    maxWidth: 500,
    left: "50%!important",
    transform: "translate(-50%, 0%)",
    paddingRight:"0px!important"
  },
});
export default function Header(props: any) {
  const { screen } =
    props;
  const classes = useStyles();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const logout = () => {
    SecureStorage.removeItem("token");
    window.location.reload();
  };


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className={classes.HeaderStyle} >
        <Toolbar >
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {props?.campLogo ? (
               <img
               src={props?.campLogo}
               alt="LOGO"
               style={{
                 padding: "5px 0px 0px",
                 maxHeight: "44px",
                 maxWidth: "200px",
               }}
             />
            ):(
              <img
              src={logo}
              alt="LOGO"
              style={{
                padding: "5px 0px 0px",
                maxHeight: "44px",
                maxWidth: "200px",
              }}
            />
            )}
           
          </Typography>
          {
            screen==="otp"?"": <div style={{ color: "red" }}>
            <Button
              onClick={() => logout()}
              variant="outlined"
              startIcon={<LogoutRoundedIcon />}
              size="small"
            >
              Logout
            </Button>
          </div>
          }
         
        </Toolbar>
      </AppBar>
    </Box>
  );
}
