import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Grid } from "@mui/material";
import StyledButton from "../components/Button/StyledButton";
import { getCamp } from "../services/actionSlice";
import { AppDispatch } from "../storage/store";
import moment from "moment";
import StyledButtonLight from "../components/Button/StyledButtonLight";

interface Props {
  setScreen: any;
  setCampData: any;
  campData: any;
}

const SalesViewCamp: React.FC<Props> = ({
  setScreen,
  setCampData,
  campData,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const campList = useSelector((state: any) => state.webAppSlice.campList);

  useEffect(() => {
    dispatch(getCamp({url:"status=INACTIVE"}));
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      {campList &&
        campList.results &&
        campList.results.length > 0 &&
        campList.results.map((data: any, index: any) => {
          return (
            <Card sx={{ borderRadius: "16px", m: 1 }}>
              <Grid container spacing={3} className="dialogContent">
                <Grid item xs={7}>
                  {data?.center_details?.name}
                  <br />
                  <sub>
                    Start Date :{" "}
                    {moment(data?.date_of_activity_start).format(
                      "DD-MM-YY, HH:mm"
                    )}
                  </sub>
                </Grid>

                <Grid item xs={5}>
                  <StyledButton
                    onClick={() => {
                      setCampData(data);
                      setScreen("form");
                    }}
                  >
                    view
                  </StyledButton>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      <div style={{ margin: "0 15px 15px" }}>
    {  campData?.id?<StyledButtonLight
          onClick={() => {
            setCampData({});
            setScreen("form");
          }}
        >
          BACK
        </StyledButtonLight>:<StyledButtonLight
          onClick={() => {
            setCampData({});
            setScreen("menu");
          }}
        >
          BACK
        </StyledButtonLight>}
        
      </div>
    </Box>
  );
};

export default SalesViewCamp;
