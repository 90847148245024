import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import "./index.css";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SecureStorage from "../../config/SecureStorage";
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import {
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
const useStyles = makeStyles({
  HeaderStyle: {
    background: "#fff!important",
    borderRadius: "15px 15px 0px 0px",
    top: "auto!important",
    bottom: 0,
    maxWidth: 500,
    left: "50%!important",
    transform: "translate(-50%, 0%)",
    paddingRight:"0px!important"
  },
  buttonNav: {
    borderRadius: "15px 15px 0px 0px!important",
    width: "100%",
    padding:"5px 20px!important"
  },
});
export default function BottomNav(props: any) {
  const { setScreen, screen } = props;
  const classes = useStyles();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const logout = () => {
    SecureStorage.removeItem("token");
    window.location.reload();
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" className={classes.HeaderStyle}>
        <div style={{ display: "flex", width: "100%", textAlign: "center" }}>
          <div style={{ width: "20%" }}>
            <Button className={classes.buttonNav} variant={screen==="partnerView"||screen==="partnerForm"?"contained":"text"} onClick={()=>setScreen("partnerView")}>
              <div>
                <PeopleAltOutlinedIcon  />
                <span>Partner</span>
              </div>
            </Button>
          </div>
          <div style={{ width: "20%" }}>
            <Button className={classes.buttonNav} variant={screen==="target"?"contained":"text"} onClick={()=>setScreen("target")}>
              <div>
                <TrendingUpOutlinedIcon />
                <span>Target</span>
              </div>
            </Button>
          </div>
          <div style={{ width: "20%" }}>
            <Button className={classes.buttonNav} variant={screen==="menu"?"contained":"text"} onClick={()=>setScreen("menu")}>
              <div>
                <HomeOutlinedIcon  />
                <span>Home</span>
              </div>
            </Button>
          </div>
          <div style={{ width: "20%" }}>
            <Button className={classes.buttonNav} variant={screen==="view"?"contained":"text"} onClick={()=>setScreen("view")}>
              <div>
                <HolidayVillageOutlinedIcon />
                <span>Camp</span>
              </div>
            </Button>
          </div>
          <div style={{ width: "20%" }}>
            <Button className={classes.buttonNav} onClick={handleClick}>
              <div>
                <MoreHorizIcon  />
                <span>More</span>
              </div>
            </Button>
          </div>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                bottom: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <MenuItem onClick={() => logout()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>
    </Box>
  );
}
