import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import "./index.css";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import logo from "../../images/logo1.png";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SecureStorage from "../../config/SecureStorage";
import {
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
const useStyles = makeStyles({
  HeaderStyle: {
    background: "#E00646",
    borderRadius: "0px 0px 15px 15px",
    top: 0,
    maxWidth: 500,
    left: "50%!important",
    transform: "translate(-50%, 0%)",
    paddingRight:"0px!important"
  },
});
export default function Header(props: any) {
  const { screen, iconRight, iconLeft } = props;
  const classes = useStyles();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const logout = () => {
    SecureStorage.removeItem("token");
    window.location.reload();
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar  className={classes.HeaderStyle}>
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={2} style={{ textAlign: "left" }}>
              <IconButton style={{ background: "#fff" }}>
                <PersonOutlineOutlinedIcon color="primary" />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography align="center" variant="h6">
                {screen === "partnerView"
                  ? "Partners"
                  : screen === "partnerForm"
                  ? "Add Partner"
                  : screen === "form"
                  ? "Camp"
                  : screen === "view"
                  ? "View Camp"
                  : "Redcliffe Labs sales"}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertOutlinedIcon style={{ color: "#fff" }} />
              </IconButton>
            </Grid>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => logout()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
          {/* <img
              src={logo}
              alt="LOGO"
              style={{
                padding: "5px 0px 0px",
                maxHeight: "44px",
                maxWidth: "200px",
              }}
            /> */}

          {/* <Button
              onClick={() => logout()}
              variant="outlined"
              startIcon={<LogoutRoundedIcon />}
              size="small"
            >
              Logout
            </Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
