import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import StyledButton from "../components/Button/StyledButton";
import { postLogin, getCamp, getOTP, resetPasswordWithOTP } from "../services/actionSlice";
import SecureStorage from "../config/SecureStorage";
import { AppDispatch } from "../storage/store";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import OtpInput from "react-otp-input";
import login from "../images/salesLogin.svg";
import StyledButtonLight from "../components/Button/StyledButtonLight";
import decode from "jwt-decode";
import { Visibility,VisibilityOff } from "@mui/icons-material";
interface Props {
  setScreen: any;
}

const SalesLogin: React.FC<Props> = ({ setScreen }) => {
  const dispatch = useDispatch<AppDispatch>();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const otpResp = useSelector((state: any) => state.webAppSlice.otpResp);
  const [loginEmail, setLoginEmail] = useState<any>("");
  const [sendOtp, setSendOtp] = useState<boolean>(false);

  const [forgot, setForgot] = useState<any>(false);

  const [loadingReset, setLoadingReset] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>("");
  const [mobileValidation, setMobileValidation] = useState({
    customer_phonenumber: true,
  });
  const [updateText, setUpdateText] = useState<number>(1);
  const [seconds, setSecond] = useState<any>(30);
  useEffect(() => {
    let timeout: any;
    if (seconds > 0) {
      timeout = setTimeout(
        () => setSecond((previousCount: number) => previousCount - 1),
        1000
      );
    } else {
      setSecond(0);
    }
    return () => clearTimeout(timeout);
  });
  const loginUser = async (e:any) => {
    e.preventDefault()
    const data: any = {
      email: loginEmail,
      password: password,
    };
    await dispatch(postLogin(data));
    authToken();

  };
  const authToken = () => {
    const token = SecureStorage.getItem("token");

    const decoded: any = decode(token);
    if (decoded?.user_group === "CAMPCR") {
      setScreen("menu");
      dispatch(getCamp({url:"status=INACTIVE"}));
    }else if(decoded?.user_group&&decoded?.user_group !== "CAMPCR"){
      SecureStorage.removeItem("token");
    }
  };
  useEffect(() => {
    if(SecureStorage.getItem("token")){
      authToken()
    }
  }, [])
  
  const ifForgetGetOTP = (e: any) => {
    setLoadingReset(true)
    e.preventDefault();
    dispatch(
      getOTP({
        phone_number: customer_phonenumber,
        otp_type: "reset_password",
        from: "app",
      })
    );
    setLoadingReset(false)
  };
  const [isOtpShow, setIsOtpShow] = useState<boolean>(false);
  const otpShowPassword = () => {
    setIsOtpShow(!isOtpShow);
  };
  const [isConfirmPassShow, setIsConfirmPassShow] = useState<boolean>(false);
  const confirmShowPassword = () => {
    setIsConfirmPassShow(!isConfirmPassShow);
  };
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [enableOtp, setEnableOtp] = useState<boolean>(true);

 
  useEffect(() => {
    if(customer_phonenumber.length === 10 && mobileValidation.customer_phonenumber === true && isFocus === false){
      setEnableOtp(false);
    }else{
      setEnableOtp(true);
    }
  }, [customer_phonenumber.length === 10, isFocus])

  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "mobile" &&
      customer_phonenumber &&
      !mobileRegEx.test(customer_phonenumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        customer_phonenumber: false,
      }));
    }
  };

  const resetPasswordHandler = async () => {
    setLoadingReset(true)
    const body: any = {
      mobile: customer_phonenumber,
      otp: otp,
      password: password,
    };
    if (otp && password) {
      dispatch(resetPasswordWithOTP(body));
    }
    setLoadingReset(false)
  };

  useEffect(() => {
    if (otpResp?.resetPasswordWithOTP?.status === true) {
      resetDetails();
    }
  }, [otpResp]);
  const resetDetails = () => {
    setForgot(false);
    setIsFocus(false);
    setIsConfirmPassShow(false);
    setIsOtpShow(false);
    updateText == 1 ? setUpdateText(2) : setUpdateText(1);
    setCustomer_phonenumber("");
    setMobileValidation({
      customer_phonenumber: true,
    });
    // setEmail("");
    setOtp("");
    setPassword("");
    // dispatch(resetPasswordReset());
    // dispatch(resetForgotPassword());
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Card sx={{ borderRadius: "16px", m: 1 }}>
        <Grid container spacing={2} className="dialogContent">
          <Grid item xs={12}>
            <img src={login} style={{ width: "100%" }} />
          </Grid>
          {!forgot ? (
            <form style={{width:"100%"}} onSubmit={loginUser}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Enter Your Email Address/Mobile No."
                  value={loginEmail}
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Enter Your Password"
                  value={password}
                  required
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
             
              <Grid item xs={12}>
                <StyledButton
                 type="submit"
                >
                  Sign In
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                <StyledButtonLight
                  onClick={() => {
                    setCustomer_phonenumber("");
                    setForgot(true);
                  }}
                >
                  Forgot Password
                </StyledButtonLight>
              </Grid>
            </form>
          ) : (
            <>
              <Grid item xs={12}>
                {" "}
                <TextField
                  id="phoneNumberId"
                  className="input"
                  margin="normal"
                  name="customer_phonenumber"
                  value={customer_phonenumber}
                  placeholder="Enter Mobile Number"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  onChange={(e) => {
                    setCustomer_phonenumber(e.target.value as String);
                  }}
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onBlur={() => {
                    verifyMobile("mobile");
                    setIsFocus(false);
                  }}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_phonenumber: true,
                    }));
                    setIsFocus(true);
                  }}
                  helperText={
                    !mobileValidation.customer_phonenumber &&
                    "Incorrect Phone Number"
                  }
                  required
                  disabled={otpResp?.get_otp?.otp_type === "reset_password"}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <StyledButton
                  disabled={
                    customer_phonenumber?.length !== 10 ||
                    loadingReset ||
                    otpResp?.get_otp?.otp_type === "reset_password"
                    //  ||
                    // enableOtp === true
                  }
                  onClick={(e)=>ifForgetGetOTP(e)}
                >
                  Send OTP
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TextField
                  className="input"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="otp"
                  type={isOtpShow ? "text" : "password"}
                  placeholder="OTP"
                  label="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  // disabled={!(otpSent && otpSent.status)}
                  disabled={otpResp?.get_otp?.otp_type !== "reset_password"}
                  style={
                    otpResp?.get_otp?.otp_type !== "reset_password"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={otpShowPassword}
                          disabled={otp.length > 0 ? false : true}
                        >
                          {isOtpShow ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TextField
                  className="input"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  type={isConfirmPassShow ? "text" : "password"}
                  placeholder="Password"
                  label="Enter New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // disabled={!(otpSent && otpSent.status)}
                  disabled={otpResp?.get_otp?.otp_type !== "reset_password"}
                  style={
                    otpResp?.get_otp?.otp_type !== "reset_password"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={confirmShowPassword}
                          disabled={password.length > 0 ? false : true}
                        >
                          {isConfirmPassShow ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {
                otpResp?.get_otp?.otp_type !== "reset_password"?"":
                <Grid item xs={12}>
                {" "}
                <StyledButton
                  disabled={
                    otp.length !== 6 || password.length < 4 || loadingReset
                  }
                  onClick={() => resetPasswordHandler()}
                >
                  Update Password
                </StyledButton>
                {loadingReset && (
                  <CircularProgress
                    style={
                      otpResp?.get_otp?.otp_type !== "reset_password"
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    size={24}
                  />
                )}
              </Grid>
              }
            
              <Grid item xs={12}>
                {" "}
                <StyledButtonLight onClick={() => resetDetails()}>
                  Login
                </StyledButtonLight>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {/* <p
              style={{
                color: "#7B7C7E",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Login Via OTP
            </p> */}
          </Grid>

          {sendOtp ? (
            <>
              <Grid item xs={12}>
                <Typography component="p" color="green">
                  OTP has been shared on your{" "}
                  {centreData?.verify_via === "M" ? "mobile" : "Email"}.{" "}
                </Typography>
                <Typography component="p">Enter OTP</Typography>
              </Grid>
              <Grid item xs={12}>
                <OtpInput
                  value={otp}
                  isInputNum={true}
                  numInputs={6}
                  onChange={(e: any) => setOtp(e)}
                  inputStyle={{
                    width: "1.7rem",
                    height: "1.7rem",
                    margin: "0 0.4rem",
                    fontSize: "14px",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                    background: "#e7e7e7",
                    color: "#000",
                  }}
                />
              </Grid>
           
              <Grid item xs={12}>
                {seconds !== 0 ? (
                  <p>
                    Resend OTP in
                    <span style={{ color: "#5866E0" }}>
                      {" "}
                      00:{seconds < 10 ? "0" + seconds : seconds}
                    </span>
                  </p>
                ) : (
                  <p>
                    <span
                      style={{ color: "#5866E0", cursor: "pointer" }}
                      onClick={() => {
                        // proceedOtp();
                        setSecond(30);
                      }}
                    >
                      Resend OTP
                    </span>
                  </p>
                )}
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Card>
    
    </Box>
  );
};

export default SalesLogin;
