import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
  Slide,
  FormGroup,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import "./pages.sass";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import StyledButton from "../components/Button/StyledButton";
import { getPartnerData } from "../services/actionSlice";
import { createStyles, withStyles } from "@mui/styles";
import { AppDispatch } from "../storage/store";
import "../container/SalesWebApp/index.sass";
import moment from "moment";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import PanoramaFishEyeRoundedIcon from "@mui/icons-material/PanoramaFishEyeRounded";
import CircleIcon from "@mui/icons-material/Circle";
import Loader from "../components/Loader";
import { TransitionProps } from "@mui/material/transitions";
import StyledButtonLight from "../components/Button/StyledButtonLight";
import SecureStorage from "../config/SecureStorage";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Props {
  setScreen: any;
  userData: any;
}
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
const SalesViewCamp: React.FC<Props> = ({ setScreen, userData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const partnerData = useSelector(
    (state: any) => state.webAppSlice.partnerData
  );

  const loading = useSelector((state: any) => state.webAppSlice.loading);
  const [pagePackageMaster, setpagePackageMaster] = useState<any>(0);
  const [approved, setApproved] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [pickupStatus, setPickupStatus] = useState<string>("");
  const [bookingType, setBookingType] = useState<any>([
    { key: "Select All", selected: true },
    { key: "CAMP", selected: true },
    { key: "Drop-Off", selected: true },
    { key: "ECG At Home", selected: true },
    { key: "Home Collectio", selected: true },
    { key: "Walkins Lab", selected: true },
    { key: "Walkins CC", selected: true },
  ]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleFilter = () => {
    let arr: any = [];
    bookingType.map((val: any, index: any) => {
      if (index !== 0 && val.selected === true) {
        arr.push(val.key);
      }
    });
    dispatch(
      getPartnerData({
        url: `?sales_manager_text=${
          userData?.profile?.fullname
        }&view_id=camp_creater_center&end_date=${endDate}&start_date=${startDate}&is_active=${approved}${
          search ? `&center_name=${search}` : ""
        }${pickupStatus ? `&pickup_status=${pickupStatus}` : ""}${
          arr.length > 0 ? `&sourcetype=${arr}` : ""
        }`,
      })
    );
    setOpen(false);
  };
  useEffect(() => {
    handleFilter();
  }, [search,approved]);

  const handlePackageMasterPagination = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > pagePackageMaster) {
      let url = partnerData.links && partnerData.links.next.split("?")[1];
      getPartnerData(url);
    } else if (newPage < pagePackageMaster) {
      let url = partnerData.links && partnerData.links.previous.split("?")[1];
      getPartnerData(url);
    }
    setpagePackageMaster(newPage as number);
  };
  function handleChange(index: any, checked: boolean) {
    let arr = [...bookingType];
    if (index === 0) {
      bookingType.map(
        (val: any, index2: any) => (arr[index2]["selected"] = checked)
      );
    } else {
      arr[index]["selected"] = checked;
      arr[0]["selected"] = false;
    }

    setBookingType(arr);
  }

  return (
    <Box sx={{ flexGrow: 1, padding: "0px 16px" }}>
      <Grid
        container
        spacing={3}
        style={{ marginTop: "0px", textAlign: "center" }}
      >
        <Grid item xs={12}>
          <StyledButton
            style={{ width: "35%" }}
            onClick={() => setScreen("partnerForm")}
          >
            Add Partner
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              borderRadius: "7px",
              border: "1px solid #D0D5DD",
              width: "70%",
              margin: "0px auto",
            }}
          >
            <div
              className="cusTab1"
              style={{ borderRight: "1px solid #D0D5DD" }}
              onClick={() => setApproved(true)}
            >
              {approved ? (
                <CircleIcon color="primary" />
              ) : (
                <PanoramaFishEyeRoundedIcon color="disabled" />
              )}
              Approved
            </div>
            <div className="cusTab1" onClick={() => setApproved(false)}>
              {!approved ? (
                <CircleIcon color="primary" />
              ) : (
                <PanoramaFishEyeRoundedIcon color="disabled" />
              )}
              Pending
            </div>
          </div>
        </Grid>
        <Grid item xs={8}>
          <TextField
            placeholder="Search"
            fullWidth
            id="filled-start-adornment"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            color="primary"
            style={{ height: "40px" }}
            onClick={() => setOpen(true)}
          >
            <FilterListRoundedIcon /> Filters
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            {loading ? (
              <Loader />
            ) : (
              <Table size="small" stickyHeader aria-label="simple">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Partner</StyledTableCell>
                    <StyledTableCell align="center">
                      Sub Partner
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Booking Type
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      No. of bookings
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Total Revenue
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {partnerData.results &&
                    partnerData.results.length > 0 &&
                    partnerData.results.map((list: any, index: any) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {list?.center_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.sub_type}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.sourcetype}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.booking_count}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {list?.revenue}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            )}
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={partnerData?.count}
                rowsPerPage={partnerData?.page_size}
                page={pagePackageMaster}
                onPageChange={handlePackageMasterPagination}
              />
            </TableFooter>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottom"
      >
        <Grid container spacing={2} className="dialogContent2">
          <Grid item xs={12}>
            <Typography component="p" fontWeight={600}>
              Date:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="contract_date"
              type="date"
              value={startDate}
              size="small"
              label="From"
              variant="outlined"
              required
              inputProps={{
                max: moment().format("YYYY-MM-DD"),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setStartDate(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="expiry_date"
              type="date"
              disabled={startDate === ""}
              value={endDate}
              size="small"
              label="To"
              inputProps={{
                min: moment(startDate).format("YYYY-MM-DD"),
                max: moment().format("YYYY-MM-DD"),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                moment(endDate).isAfter(startDate)
                  ? ""
                  : "To Date Should Greater Than From Date"
              }
              variant="outlined"
              onChange={(e) => setEndDate(e.target.value as string)}
              style={{ width: "100%" }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" fontWeight={600}>
              Booking Type:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              {bookingType.length > 0 &&
                bookingType.map((val: any, index: any) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={val.selected}
                        onChange={(e) => handleChange(index, e.target.checked)}
                        name="gilad"
                      />
                    }
                    label={val?.key}
                  />
                ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" fontWeight={600}>
              Pickup Status:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={pickupStatus}
              onChange={(e) => setPickupStatus(e.target.value)}
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label="Select All"
              />
              <FormControlLabel value="hold" control={<Radio />} label="Hold" />
              <FormControlLabel
                value="collected"
                control={<Radio />}
                label="Collected"
              />
              <FormControlLabel
                value="cancelled"
                control={<Radio />}
                label="Cancelled"
              />
              <FormControlLabel
                value="pending"
                control={<Radio />}
                label="Pending"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={6}>
            <StyledButtonLight onClick={() => handleClose()}>
              Cancel
            </StyledButtonLight>
          </Grid>
          <Grid item xs={6}>
            <StyledButton onClick={() => handleFilter()}>Apply</StyledButton>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

export default SalesViewCamp;
